import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface EditorButtonProps {
    disabled?: boolean;
    icon?: IconDefinition;
    text?: string;
    shortcutText?: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export default function EditorButton({
    disabled,
    icon,
    text,
    shortcutText,
    onClick,
}: EditorButtonProps) {
    return (
        <button
            className="block bg-slate-600 active:bg-slate-900 disabled:bg-slate-800 disabled:text-slate-400 rounded px-3 py-1 my-5 mx-1 w-full min-h-10 text-white"
            onClick={onClick}
            disabled={disabled ?? false}
        >
            <div className="flex flex-col">
                <div className="flex flex-row w-full justify-center items-center">
                    {icon && (<FontAwesomeIcon icon={icon} fixedWidth className={`${text ? "mr-2" : ""}`} />)}
                    {text && (<div className="text-lg">{text}</div>)}
                </div>
                <div>
                    {shortcutText && (<div className="text-sm text-slate-300">Shortcut: {shortcutText}</div>)}
                </div>
            </div>

        </button>
    );
}
