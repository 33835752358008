import { faBed, faRadiation, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditorButton from "../components/EditorButton";
import { getEditorService } from "../EditorService";
import Frame from "../Frame";
import { WebGL2Colour } from "../Constants";
import { getEditorController } from "../EditorController";

interface DeveloperMenuProps {
    setMenuVisibility: React.Dispatch<React.SetStateAction<boolean>>,
}
export default function DeveloperMenu({
    setMenuVisibility,
}:DeveloperMenuProps) {
    return (
        <div className="p-2 lg:p-4 w-full">
            <div className="flex flex-row">
                <div className="w-1/2 flex justify-start">
                    <h1 className="text-3xl">Developer</h1>
                </div>
                <div className="w-1/2 flex justify-end">
                    <button onClick={() => setMenuVisibility(false)}>
                        <FontAwesomeIcon className="text-2xl fa-fw" icon={faX} />
                    </button>
                </div>
            </div>
            <p>This menu contains experimental features that may or may not work in the past, present, or future.</p>

            <p className="text-sm">Import / export moved to settings menu (gear icon)</p>

            <h2 className="text-xl mt-5">Utilities</h2>
            <p className="text-sm">Now it's gettin' real scary</p>
            <EditorButton
                icon={faRadiation}
                text="Randomize"
                onClick={() => {
                    const editorController = getEditorController();
                    const editorService = getEditorService();

                    let frame: Frame | undefined = editorService.getAnimation().getFirstFrame();
                    let count = 0;
                    const limit = 10;

                    function generateRandomColour(): WebGL2Colour {
                        return [Math.floor(Math.random() * 255), Math.floor(Math.random() * 255), Math.floor(Math.random() * 255), 255];
                    }

                    while (frame && count < limit) {
                        const data = frame.getData();

                        for (let i = 0; i < data.length; i++) {
                            data[i] = Math.floor(Math.random() * 255);
                        }

                        frame.setPaperColour(generateRandomColour());
                        frame.setColourPalette([
                            generateRandomColour(),
                            generateRandomColour(),
                            generateRandomColour(),
                        ]);

                        frame = editorService.getAnimation().insertNewFrameBefore();
                    }

                    editorController.refreshEditorUI();
                }}
            />

            <EditorButton
                icon={faBed}
                text="Sleep 5s"
                onClick={() => {
                    const start = performance.now();
                    while (performance.now() - start < 5000) { };
                }}
            />
        </div>
    );
}
