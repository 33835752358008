import Animation from "./Animation";
import { CANVAS_HEIGHT, CANVAS_WIDTH } from "./Constants";
import Frame from "./Frame";
import { Rectangle } from "./GeneralTypes";
import { CommandType } from "./commands/Commands";
import { CopyMessage } from "./messages/Messages";

export interface EditorClipboardData {
    data: Uint8Array,
    layerIndex: number,
    dimensions: Rectangle,
}

export default class EditorClipboard {
    private frameId?: number;
    private data?: Uint8Array;
    private layerIndex?: number;
    private dimensions?: Rectangle;

    constructor() { }

    copy(frame: Frame, layerIndex: number, dimensions: Rectangle): void {
        this.frameId = frame.getId();
        this.data = new Uint8Array(frame.getData());
        this.layerIndex = layerIndex;
        this.dimensions = dimensions;
    }

    getClipboardData(): EditorClipboardData | undefined {
        const { data, layerIndex, dimensions } = this;

        if (data && layerIndex != null && dimensions) {
            return {
                data,
                layerIndex,
                dimensions
            };
        }
    }

    createCopyMessage(): CopyMessage | undefined {
        const { data, layerIndex, dimensions, frameId } = this;

        if (data && layerIndex != null && dimensions && frameId != null) {
            return {
                type: CommandType.COPY,
                frameId,
                layerIndex,
                dimensions
            };
        }
    }

    handleCopyMessage(message: CopyMessage, animation: Animation): void {
        const { frameId, layerIndex, dimensions } = message;
        const frame = animation.getFrameById(frameId);

        if (frame) {
            this.copy(frame, layerIndex, dimensions);
        }
    }
}