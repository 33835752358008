import { DEFAULT_STROKE_DIAMETER, DEFAULT_DRAW_LAYER, DEFAULT_DRAW_MASK, DEFAULT_ONION_SKIN_FRAME_COUNT, DEFAULT_BRUSH_PATTERN, ColourPalette, DEFAULT_COLOUR_PALETTE, WebGL2Colour, DEFAULT_BACKWARD_ONION_SKIN_COLOUR, DEFAULT_FORWARD_ONION_SKIN_COLOUR, DEFAULT_ONION_SKIN_NATURAL_COLOURS, DEFAULT_ZOOM, DEFAULT_PAN } from "./Constants"
import Animation from "./Animation";
import { Point, Rectangle } from "./GeneralTypes";
import { BrushPattern } from "./enums/BrushPattern";
import StrokeCommand from "./commands/StrokeCommand";
import EditHistoryController from "./EditHistoryController";
import EditorClipboard from "./EditorClipboard";
import Frame from "./Frame";

let editorService: EditorService | undefined;
export function getEditorService() {
    if (!editorService) {
        editorService = new EditorService();
    }

    return editorService;
}

class EditorService {
    private animation: Animation;
    private colours: {
        currentIndex: number,
    }
    private currentTool: EditorTool;
    private frame: {
        currentId: number,
    };
    private onionSkin: {
        backward: boolean,
        forward: boolean,
        naturalColours: boolean,
        backwardColour: WebGL2Colour,
        forwardColour: WebGL2Colour,
        frameCount: number,
    }
    private mouseCoords: {
        lastX: number | null,
        lastY: number | null,
        currentX: number | null,
        currentY: number | null,
    };
    private layer: {
        currentIndex: number,
        backgroundVisible: boolean,
        middlegroundVisible: boolean,
        foregroundVisible: boolean,
    };
    private canvasViewTransformations: CanvasViewTransformations;
    private state: EditorState;
    private brushStrokeDiameter: number;
    private brushPattern: BrushPattern;
    private eraserStrokeDiameter: number;
    private webSocket?: WebSocket;
    private userState: UserState;
    private playbackState: PlaybackState; 
    private collaboratorMap: Map<number, UserState>;
    private floatingFrame: Frame;
    private floatingFrameOffset: Point;
    private editorStateStack: EditorState[];

    constructor() {
        this.animation = new Animation();
        this.currentTool = EditorTool.BRUSH; 
        this.colours = {
            currentIndex: DEFAULT_DRAW_MASK,
        };
        this.frame = {
            currentId: 0,
        };
        this.onionSkin = {
            backward: false,
            forward: false,
            naturalColours: DEFAULT_ONION_SKIN_NATURAL_COLOURS,
            backwardColour: DEFAULT_BACKWARD_ONION_SKIN_COLOUR,
            forwardColour: DEFAULT_FORWARD_ONION_SKIN_COLOUR,
            frameCount: DEFAULT_ONION_SKIN_FRAME_COUNT,
        };
        this.mouseCoords = {
            lastX: null,
            lastY: null,
            currentX: null,
            currentY: null,
        }
        this.layer = {
            currentIndex: DEFAULT_DRAW_LAYER,
            backgroundVisible: true,
            middlegroundVisible: true,
            foregroundVisible: true,
        };
        this.state = EditorState.IDLE;
        this.brushStrokeDiameter = DEFAULT_STROKE_DIAMETER;
        this.brushPattern = DEFAULT_BRUSH_PATTERN;
        this.eraserStrokeDiameter = DEFAULT_STROKE_DIAMETER;
        this.userState = {
            editHistoryController: new EditHistoryController(),
            clipboard: new EditorClipboard(),
        };
        this.playbackState = {
            timeCounter: 0,
            benchmarkFrameCount: 0,
            benchmarkTotalTime: 0,
        }
        this.collaboratorMap = new Map<number, UserState>();
        this.floatingFrameOffset = [0, 0];
        this.floatingFrame = new Frame(0, 0);
        this.canvasViewTransformations = {
            zoom: DEFAULT_ZOOM,
            panX: DEFAULT_PAN,
            panY: DEFAULT_PAN,
        }
        this.editorStateStack = [];
    }

    getCanvasViewTransformations(): CanvasViewTransformations {
        return this.canvasViewTransformations;
    } 

    getPlaybackState(): PlaybackState {
        return this.playbackState;
    }

    getAnimation(): Animation {
        return this.animation;
    }

    getColourIndex(): ColourIndex {
        return this.colours.currentIndex;
    }

    getCurrentStrokeColour(): ColourIndex {
        if (this.currentTool === EditorTool.ERASER) {
            return ColourIndex.CLEAR;
        }

        return this.colours.currentIndex;
    }

    getBrushStrokeDiameter(): number {
        return this.brushStrokeDiameter;
    }

    getBrushPattern(): BrushPattern {
        return this.brushPattern;
    }

    getEraserStrokeDiameter(): number {
        return this.eraserStrokeDiameter;
    }

    getCurrentTool(): EditorTool {
        return this.currentTool;
    }

    getFloatingFrame(): Frame {
        return this.floatingFrame;
    }

    getCurrentFrameId(): number {
        return this.frame.currentId;
    }

    getCurrentColourPalette(): ColourPalette {
        return this.animation.getFrameById(this.frame.currentId)?.getColourPalette() ?? DEFAULT_COLOUR_PALETTE;
    }

    getCurrentLayerIndex(): number {
        return this.layer.currentIndex;
    }

    getLastMouseCoords(): Point  | undefined {
        if (this.mouseCoords.lastX && this.mouseCoords.lastY) {
            return [ this.mouseCoords.lastX, this.mouseCoords.lastY ];
        }
    }

    getMouseCoords(): Point  | undefined {
        if (this.mouseCoords.currentX && this.mouseCoords.currentY) {
            return [ this.mouseCoords.currentX, this.mouseCoords.currentY ];
        }
    }

    getLayerVisibility(): [boolean, boolean, boolean] {
        const { backgroundVisible, middlegroundVisible, foregroundVisible } = this.layer;
        return [backgroundVisible, middlegroundVisible, foregroundVisible];
    }

    getState(): EditorState {
        return this.state;
    }

    getUserId(): number | undefined {
        return this.userState.id;
    }

    getOnionSkinSettings(): [boolean, boolean] {
        return [ this.onionSkin.backward, this.onionSkin.forward ];
    }

    getOnionSkinColourSetting(): boolean {
        return this.onionSkin.naturalColours;
    }

    getOnionSkinBackwardColour(): WebGL2Colour {
        return this.onionSkin.backwardColour;
    }

    getOnionSkinForwardColour(): WebGL2Colour {
        return this.onionSkin.forwardColour;
    }

    getWebSocket(): WebSocket | undefined {
        return this.webSocket;
    }

    getCurrentStroke(): StrokeCommand | undefined {
        return this.userState.currentStroke;
    }

    getCollaboratorCurrentStroke(collaboratorId: number): StrokeCommand | undefined {
        return this.collaboratorMap.get(collaboratorId)?.currentStroke;
    }

    getUserEditHistoryController(): EditHistoryController {
        return this.userState.editHistoryController;
    }

    getCollaboratorEditHistoryController(collaboratorId: number): EditHistoryController | undefined {
        return this.collaboratorMap.get(collaboratorId)?.editHistoryController;
    }

    getUserClipboard(): EditorClipboard {
        return this.userState.clipboard;
    }

    getCollaboratorClipboard(collaboratorId: number): EditorClipboard | undefined {
        return this.collaboratorMap.get(collaboratorId)?.clipboard;
    }

    getUserSelection(): Rectangle | undefined {
        return this.userState.selection;
    } 

    getFloatingFrameOffset(): Point {
        return this.floatingFrameOffset;
    }

    resetMouseCoords(): void {
        this.mouseCoords.currentX = null;
        this.mouseCoords.currentY = null;
        this.mouseCoords.lastX = null;
        this.mouseCoords.lastY = null;
    }

    resetUserSelection(): void {
        this.userState.selection = undefined;
    }

    setCurrentStroke(stroke?: StrokeCommand): void {
        this.userState.currentStroke = stroke;
    }

    setCollaboratorCurrentStroke(collaboratorId: number, stroke?: StrokeCommand): void {
        const collaboratorState = this.collaboratorMap.get(collaboratorId);
        if (collaboratorState) {
            collaboratorState.currentStroke = stroke;
        }
    }

    setFloatingFrameOffset(x: number, y: number): void {
        this.floatingFrameOffset[0] = x;
        this.floatingFrameOffset[1] = y;
    }

    setLastMouseCoords(x: number, y: number): void {
        this.mouseCoords.lastX = x;
        this.mouseCoords.lastY = y;
    }

    setMouseCoords(currentX: number, currentY: number): void {
        this.mouseCoords.currentX = currentX;
        this.mouseCoords.currentY = currentY;
    }

    setLayerVisibility(backgroundVisible: boolean, middlegroundVisible: boolean, foregroundVisible: boolean): void {
        this.layer.backgroundVisible = backgroundVisible;
        this.layer.middlegroundVisible = middlegroundVisible;
        this.layer.foregroundVisible = foregroundVisible;
    }

    setColourIndex(colourIndex: ColourIndex): void {
        this.colours.currentIndex = colourIndex;
    }

    setCurrentTool(editorTool: EditorTool): void {
        this.currentTool = editorTool;
    }

    setCurrentLayerIndex(layerIndex: number): void {
        this.layer.currentIndex = layerIndex;
    }

    setUserSelection(x: number, y: number, width: number, height: number): void {
        this.userState.selection = {
            x,
            y,
            width,
            height
        };
    }

    setBrushStrokeDiameter(diameter: number): void {
        this.brushStrokeDiameter = diameter;
    }

    setBrushPattern(brushPattern: BrushPattern): void {
        this.brushPattern = brushPattern;
    }

    setEraserStrokeDiameter(diameter: number): void {
        this.eraserStrokeDiameter = diameter;
    }

    setCurrentFrameId(frameId: number): void {
        this.frame.currentId = frameId;
    }

    setOnionSkinSettings(backward: boolean, forward: boolean): void {
        this.onionSkin.backward = backward;
        this.onionSkin.forward = forward;
    }

    setOnionSkinColourSetting(naturalColours: boolean): void {
        this.onionSkin.naturalColours = naturalColours;
    }

    setOnionSkinBackwardColour(colour: WebGL2Colour): void {
        this.onionSkin.backwardColour = colour;
    }

    setOnionSkinForwardColour(colour: WebGL2Colour): void {
        this.onionSkin.forwardColour = colour;
    }

    setWebSocket(webSocket: WebSocket): void {
        this.webSocket = webSocket;
    }

    setAnimation(animation: Animation): void {
        this.animation = animation;
        this.setCurrentFrameId(animation.getFirstFrame().getId());
    }

    setState(nextEditorState: EditorState): boolean {
        if (this.state === nextEditorState) {
            return false;
        }

        const state = this.state;

        if (this.state === EditorState.DRAWING) {
            if (
                [
                    EditorState.IDLE,
                ]
                    .includes(nextEditorState)
            ) {
                this.state = nextEditorState;
            }
        } else if (this.state === EditorState.IDLE) {
            if (
                [
                    EditorState.DRAWING,
                    EditorState.PLAYING,
                    EditorState.SELECTING,
                    EditorState.SELECTION_IDLE,
                    EditorState.TRANSFORMING,
                    EditorState.PANNING,
                ]
                    .includes(nextEditorState)
            ) {
                this.state = nextEditorState;
            }
        } else if (this.state === EditorState.PLAYING) {
            if (
                [
                    EditorState.IDLE,
                ]
                    .includes(nextEditorState)
            ) {
                this.state = nextEditorState;
            }
        } else if (this.state === EditorState.SELECTING) {
            if (
                [
                    EditorState.IDLE,
                    EditorState.SELECTION_IDLE,
                ]
                    .includes(nextEditorState)
            ) {
                this.state = nextEditorState;
            }
        } else if (this.state === EditorState.SELECTION_IDLE) {
            if (
                [
                    EditorState.IDLE,
                    EditorState.TRANSFORMING,
                    EditorState.SELECTING,
                    EditorState.PLAYING,
                    EditorState.PANNING,
                ]
                    .includes(nextEditorState)
            ) {
                this.state = nextEditorState;
            }
        } else if (this.state === EditorState.TRANSFORMING) {
            if (
                [
                    EditorState.IDLE,
                    EditorState.TRANSLATING,
                    EditorState.SELECTING,
                    EditorState.PANNING,
                ]
                    .includes(nextEditorState)
            ) {
                this.state = nextEditorState;
            }
        } else if (this.state === EditorState.TRANSLATING) {
            if (
                [
                    EditorState.TRANSFORMING,
                ]
                    .includes(nextEditorState)
            ) {
                this.state = nextEditorState;
            }
        } else if (this.state === EditorState.PANNING) {
            if (
                [
                    EditorState.IDLE,
                    EditorState.SELECTION_IDLE,
                    EditorState.TRANSFORMING,
                ]
                    .includes(nextEditorState)
            ) {
                this.state = nextEditorState;
            }
        }

        if (process.env.NODE_ENV === "development") {
            console.log({ state, nextEditorState, transitioned: this.state === nextEditorState });
        }

        return this.state === nextEditorState;
    }

    setUserId(userId: number): void {
        this.userState.id = userId;
    }

    addCollaborator(collaboratorId: number): void {
        this.collaboratorMap.set(collaboratorId, {
            id: collaboratorId,
            currentStroke: undefined,
            editHistoryController: new EditHistoryController(),
            clipboard: new EditorClipboard(),
        });
    }

    removeCollaborator(collaboratorId: number): void {
        this.collaboratorMap.delete(collaboratorId);
    }

    editorStatePush(state: EditorState): void {
        this.editorStateStack.push(state);
    }

    editorStatePop(): EditorState {
        const state = this.editorStateStack.pop();
        return state ?? EditorState.IDLE;
    }

    editorStatePeek(): EditorState {
        const state: EditorState | undefined = this.editorStateStack[this.editorStateStack.length - 1];
        return state ?? EditorState.IDLE;
    }
}

interface CanvasViewTransformations {
    zoom: number,
    panX: number,
    panY: number,
}

interface UserState {
    id?: number;
    currentStroke?: StrokeCommand;
    editHistoryController: EditHistoryController;
    clipboard: EditorClipboard;
    selection?: Rectangle;
}

export enum ColourIndex {
    CLEAR = 0,
    A = 1,
    B = 2,
    C = 3,
}

export enum EditorTool {
    BRUSH = "BRUSH",
    ERASER = "ERASER",
    SELECT = "SELECT",
}

export enum EditorState {
    IDLE = "IDLE",
    DRAWING = "DRAWING",
    PLAYING = "PLAYING",
    SELECTING = "SELECTING",
    SELECTION_IDLE = "SELECTION_IDLE",
    TRANSFORMING = "TRANSFORMING",
    TRANSLATING = "TRANSLATING",
    PANNING = "PANNING",
}

export interface PlaybackState {
    timeCounter: number,
    requestId?: number,
    lastFrameTimestamp?: number,
    benchmarkFrameCount: number,
    benchmarkTotalTime: number,
}
