import React, { useEffect, useState } from 'react';
import { EditorTool, getEditorService } from './EditorService';
import { CANVAS_HEIGHT, CANVAS_WIDTH, DEFAULT_STROKE_DIAMETER, DEFAULT_DRAW_LAYER, DEFAULT_BRUSH_PATTERN, WebGL2Colour, MAX_ANIMATION_FRAME_COUNT, COLOUR_WHITE, DEFAULT_BACKWARD_ONION_SKIN_COLOUR, DEFAULT_FORWARD_ONION_SKIN_COLOUR, DEFAULT_ONION_SKIN_NATURAL_COLOURS } from './Constants';
import { SessionData } from '../../App';
import { faBorderTopLeft, faDownload, faEraser, faFilm, faFlask, faGear, faHand, faLaptopCode, faLeftLong, faMinus, faPause, faPlay, faRepeat, faRightLong, faRotateLeft, faRotateRight, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import PlaybackButton from './components/PlaybackButton';
import ToolbarButton from './components/ToolbarButton';
import LayerToolbarButton from './components/LayerToolbarButton';
import Canvas, { toggleLoop } from './Canvas';
import BrushToolbarButton from './components/BrushToolbarButton';
import BrushMenu from './menus/BrushMenu';
import { colourToHex } from './EditorUtils';
import EraserMenu from './menus/EraserMenu';
import LayerMenu from './menus/LayerMenu';
import FrameMenu from './menus/FrameMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEditorController } from './EditorController';
import SelectMenu from './menus/SelectMenu';
import DeveloperMenu from './menus/DeveloperMenu';
import HandMenu from './menus/HandMenu';
import SettingsMenu from './menus/SettingsMenu';

interface EditorProps {
    sessionData: SessionData,
}

export enum EditorMenu {
    BRUSH = "BRUSH",
    ERASER = "ERASER",
    SELECT = "SELECT",
    LAYER = "LAYER",
    FRAME = "FRAME",
    DEVELOPER = "DEVELOPER",
    HAND = "HAND",
    SETTINGS = "SETTINGS",
}

const editorState = getEditorService();
const editorController = getEditorController();

// TODO fix this one day please
export interface EditorUIState {
    setCurrentFrameIndex: (a: number) => void,
    setFrameCount: (a: number) => void,
    setPlaying: (a: boolean) => void,
    setLoop: (a: boolean) => void,
    setCurrentColour: (a: WebGL2Colour) => void,
    setColourA: (a: string) => void,
    setColourB: (a: string) => void,
    setColourC: (a: string) => void,
    setCurrentDrawLayer: (a: number) => void,
    setBrushStrokeDiameter: (a: number) => void,
    setEraserStrokeDiameter: (a: number) => void,
    setTool: (a: EditorTool) => void,
    setBackgroundVisibility: (a: boolean) => void,
    setMiddlegroundVisibility: (a: boolean) => void,
    setForegroundVisibility: (a: boolean) => void,
    setFps: (a: number) => void,
    setBackwardOnionSkin: (a: boolean) => void,
    setForwardOnionSkin: (a: boolean) => void,
    setOnionSkinNaturalColours: (a: boolean) => void,
    setBackwardOnionSkinColour: (a: string) => void,
    setForwardOnionSkinColour: (a: string) => void,
    setCurrentPaperColour: (a: string) => void,
    setDeveloperModeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Editor({ sessionData }: EditorProps) {
    const [brushStrokeDiameter, setBrushStrokeDiameter] = useState(DEFAULT_STROKE_DIAMETER);
    const [eraserStrokeDiameter, setEraserStrokeDiameter] = useState(DEFAULT_STROKE_DIAMETER);
    const [backgroundVisibility, setBackgroundVisibility] = useState(true);
    const [middlegroundVisibility, setMiddlegroundVisibility] = useState(true);
    const [foregroundVisibility, setForegroundVisibility] = useState(true);
    const [currentDrawLayer, setCurrentDrawLayer] = useState(DEFAULT_DRAW_LAYER);
    const [fps, setFps] = useState(12);

    const [ backwardOnionSkin, setBackwardOnionSkin ] = useState(false);
    const [ forwardOnionSkin, setForwardOnionSkin ] = useState(false);
    const [ onionSkinNaturalColours, setOnionSkinNaturalColours ] = useState(DEFAULT_ONION_SKIN_NATURAL_COLOURS);
    const [ backwardOnionSkinColour, setBackwardOnionSkinColour ] = useState(colourToHex(DEFAULT_BACKWARD_ONION_SKIN_COLOUR));
    const [ forwardOnionSkinColour, setForwardOnionSkinColour ] = useState(colourToHex(DEFAULT_FORWARD_ONION_SKIN_COLOUR));

    const [tool, setTool] = useState(EditorTool.BRUSH);

    const [currentFrameIndex, setCurrentFrameIndex] = useState(0);
    const [currentColour, setCurrentColour] = useState(editorState.getCurrentColourPalette()[0]);
    const [currentPaperColour, setCurrentPaperColour] = useState(colourToHex(COLOUR_WHITE));
    const [frameCount, setFrameCount] = useState(1);
    const [loop, setLoop] = useState(true);
    const [playing, setPlaying] = useState(false);

    const [colourA, setColourA] = useState(colourToHex(editorState.getCurrentColourPalette()[0]));
    const [colourB, setColourB] = useState(colourToHex(editorState.getCurrentColourPalette()[1]));
    const [colourC, setColourC] = useState(colourToHex(editorState.getCurrentColourPalette()[2]));
    const [brushPattern, setBrushPattern] = useState(DEFAULT_BRUSH_PATTERN)

    const [currentMenu, setCurrentMenu] = useState(EditorMenu.BRUSH);
    const [menuVisibility, setMenuVisibility] = useState(true);

    const [developerModeEnabled, setDeveloperModeEnabled] = useState(false);

    const editorUIState: EditorUIState = {
        setCurrentFrameIndex,
        setFrameCount,
        setPlaying,
        setLoop,
        setTool,
        setCurrentColour,
        setColourA,
        setColourB,
        setColourC,
        setCurrentDrawLayer,
        setBrushStrokeDiameter,
        setEraserStrokeDiameter,
        setForegroundVisibility,
        setBackgroundVisibility,
        setFps,
        setBackwardOnionSkin,
        setForwardOnionSkin,
        setOnionSkinNaturalColours,
        setBackwardOnionSkinColour,
        setForwardOnionSkinColour,
        setCurrentPaperColour,
        setMiddlegroundVisibility,
        setDeveloperModeEnabled,
    }

    editorController.setEditorUIState(editorUIState);

    const totalSeconds = frameCount / fps;
    const totalMinutesString = Math.floor(totalSeconds / 60).toString().padStart(2, "0");
    const totalSecondsString = Math.floor(totalSeconds % 60).toString().padStart(2, "0");
    
    const currentSeconds = currentFrameIndex / fps;
    const currentMinutesString = Math.floor(currentSeconds / 60).toString().padStart(2, "0");
    const currentSecondsString = Math.floor(currentSeconds % 60).toString().padStart(2, "0");

    const [canvasHeightValue, setCanvasHeightValue] = useState(CANVAS_HEIGHT);
    const [windowScreenDimensions, setWindowScreenDimensions] = useState([1280, 720]);

    useEffect(() => {
        const handleResize = () => {
            const dimensions = [window.innerWidth, window.innerHeight];
            setWindowScreenDimensions(dimensions);

            const canvas = document.getElementById("editor-canvas");
            if (canvas) {
                setCanvasHeightValue(canvas.offsetHeight);
            }
        };

        handleResize();

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="h-screen w-screen bg-slate-900 flex flex-col landscape:justify-center text-white landscape:text-lg font-sans">
            <div className="w-full flex justify-center text-sm">
                <p>Session ID: {sessionData.sessionId}</p>
            </div>

            <div className="flex flex-col landscape:flex-row justify-center">
                <div className="landscape:w-1/4"></div>

                <div className="flex flex-col w-full landscape:w-[85vh]">
                    <Canvas
                        width={CANVAS_WIDTH}
                        height={CANVAS_HEIGHT}
                        setDrawDiameter={setBrushStrokeDiameter}
                        setCurrentDrawLayer={setCurrentDrawLayer}
                        ui={editorUIState}
                        setLoop={setLoop}
                        setUIPlaying={setPlaying}
                        setTool={setTool}
                        sessionData={sessionData}
                    />

                    <div className="flex flex-row bg-slate-950 p-1 landscape:bg-inherit">
                        <div className="w-1/6 md:w-1/4 flex flex-row text-xs xl:text-sm font-mono">
                            <p>{currentMinutesString}:{currentSecondsString}<br className="md:hidden"/>/<span className="font-bold">{totalMinutesString}:{totalSecondsString}</span></p>
                        </div>

                        <div className="w-4/6 md:w-1/2 flex flex-row justify-evenly text-3xl lg:text-3xl xl:text-4xl 2xl:text-5xl">
                            <PlaybackButton icon={faLeftLong} highlightCondition={!playing && currentFrameIndex !== 0} onClick={() => {editorController.displayPreviousFrame() }} />
                            <PlaybackButton icon={playing ? faPause : faPlay} highlightCondition={true} onClick={() => { editorController.togglePlayPause() }} />
                            <PlaybackButton icon={faRepeat} highlightCondition={loop} onClick={() => {toggleLoop(setLoop)}} />
                            <PlaybackButton icon={faRightLong} highlightCondition={!playing && currentFrameIndex !== MAX_ANIMATION_FRAME_COUNT - 1} onClick={() => {editorController.displayNextFrame()}} />
                        </div>

                        <div className="w-1/6 md:w-1/4 flex flex-col md:flex-row justify-end text-right text-xs xl:text-sm font-mono">
                            <p>{currentFrameIndex + 1}/<span className="font-bold">{frameCount}</span></p>
                            <p className="hidden md:block">&nbsp;</p>
                            <p>(<span className="font-bold">{fps}</span> fps)</p>
                        </div>
                    </div>
                </div>

                <div className={`flex flex-col w-full landscape:w-1/4 landscape:flex-row ${ menuVisibility ? "" : "display-none" }`} style={{ height: windowScreenDimensions[0] >= windowScreenDimensions[1] ? canvasHeightValue : "100%" }}>
                    <div className="flex flex-row text-xl justify-start lg:text-2xl xl:text-2xl 2xl:text-3xl landscape:flex-col bg-slate-800">
                        <BrushToolbarButton
                            colour={currentColour}
                            selected={tool === EditorTool.BRUSH}
                            menuOpen={menuVisibility && currentMenu === EditorMenu.BRUSH}
                            setCurrentMenu={setCurrentMenu}
                            setMenuVisibility={setMenuVisibility}
                            menuVisibility={menuVisibility}
                            currentMenu={currentMenu}
                        />
                        <ToolbarButton
                            selected={tool === EditorTool.ERASER}
                            menuOpen={menuVisibility && currentMenu === EditorMenu.ERASER}
                            icon={faEraser}
                            onClick={(e) => {
                                setCurrentMenu(EditorMenu.ERASER);
                                editorController.setCurrentTool(EditorTool.ERASER);
                            }}
                            onContextMenu={(e) => {
                                e.preventDefault();
                                setCurrentMenu(EditorMenu.ERASER);
                                setMenuVisibility(!(menuVisibility && currentMenu === EditorMenu.ERASER));
                            }}
                        />
                        <ToolbarButton
                            selected={tool === EditorTool.SELECT}
                            menuOpen={menuVisibility && currentMenu === EditorMenu.SELECT}
                            icon={faBorderTopLeft}
                            onClick={(e) => {
                                setCurrentMenu(EditorMenu.SELECT);
                                editorController.setCurrentTool(EditorTool.SELECT);
                            }}
                            onContextMenu={(e) => {
                                e.preventDefault();
                                setCurrentMenu(EditorMenu.SELECT);
                                setMenuVisibility(!(menuVisibility && currentMenu === EditorMenu.SELECT));
                            }}
                        />
                        <ToolbarButton
                            menuOpen={menuVisibility && currentMenu === EditorMenu.HAND}
                            icon={faHand}
                            onClick={() => {
                                setCurrentMenu(EditorMenu.HAND);
                                setMenuVisibility(true);
                            }}
                            onContextMenu={(e) => {
                                e.preventDefault();
                                setCurrentMenu(EditorMenu.HAND);
                                setMenuVisibility(!(menuVisibility && currentMenu === EditorMenu.HAND));
                            }}
                        />

                        <FontAwesomeIcon className="text-slate-700 portrait:hidden -my-1" icon={faMinus} transform={"grow-10"} />
                        <FontAwesomeIcon className="text-slate-700 landscape:hidden fa-fw fa-rotate-90 -mx-1" icon={faMinus} transform={"grow-8 down-3"} />

                        <LayerToolbarButton
                            menuOpen={menuVisibility && currentMenu === EditorMenu.LAYER}
                            currentDrawLayer={currentDrawLayer}
                            setCurrentMenu={setCurrentMenu}
                            setMenuVisibility={setMenuVisibility}
                            menuVisibility={menuVisibility}
                            currentMenu={currentMenu}
                        />
                        <ToolbarButton
                            menuOpen={menuVisibility && currentMenu === EditorMenu.FRAME}
                            icon={faFilm}
                            onClick={() => {
                                setCurrentMenu(EditorMenu.FRAME);
                                setMenuVisibility(true);
                            }}
                            onContextMenu={(e) => {
                                e.preventDefault();
                                setCurrentMenu(EditorMenu.FRAME);
                                setMenuVisibility(!(menuVisibility && currentMenu === EditorMenu.FRAME));
                            }}
                        />
                        <ToolbarButton
                            icon={faRotateLeft}
                            onClick={() => { editorController.undo() }}
                            onContextMenu={(e) => {
                                e.preventDefault();
                            }}
                        />
                        <ToolbarButton
                            icon={faRotateRight}
                            onClick={() => { editorController.redo() }}
                            onContextMenu={(e) => {
                                e.preventDefault();
                            }}
                        />
                        <ToolbarButton
                            icon={faGear}
                            onClick={() => {
                                setCurrentMenu(EditorMenu.SETTINGS);
                                setMenuVisibility(true);
                            }}
                            onContextMenu={(e) => {
                                e.preventDefault();
                                setCurrentMenu(EditorMenu.SETTINGS);
                                setMenuVisibility(!(menuVisibility && currentMenu === EditorMenu.SETTINGS));
                            }}
                        />
                        {
                            (developerModeEnabled || process.env.NODE_ENV === "development") && (
                                <ToolbarButton
                                    icon={faLaptopCode}
                                    onClick={() => {
                                        setCurrentMenu(EditorMenu.DEVELOPER);
                                        setMenuVisibility(true);
                                    }}
                                    onContextMenu={(e) => {
                                        e.preventDefault();
                                        setCurrentMenu(EditorMenu.DEVELOPER);
                                        setMenuVisibility(!(menuVisibility && currentMenu === EditorMenu.DEVELOPER));
                                    }}
                                />
                            )
                        }
                    </div>

                    {
                        menuVisibility === true && (
                            <div className="max-h-[40vh] landscape:max-h-full bg-slate-700 flex w-full overflow-y-scroll">
                                {
                                    currentMenu === EditorMenu.BRUSH && (
                                        <BrushMenu
                                            colourA={colourA}
                                            colourB={colourB}
                                            colourC={colourC}
                                            brushStrokeDiameter={brushStrokeDiameter}
                                            brushPattern={brushPattern}
                                            setCurrentColour={setCurrentColour}
                                            setColourA={setColourA}
                                            setColourB={setColourB}
                                            setColourC={setColourC}
                                            setBrushStrokeDiameter={setBrushStrokeDiameter}
                                            setMenuVisibility={setMenuVisibility}
                                            setBrushPattern={setBrushPattern}
                                        />
                                    )
                                }
                                {
                                    currentMenu === EditorMenu.ERASER && (
                                        <EraserMenu
                                            eraserStrokeDiameter={eraserStrokeDiameter}
                                            setEraserStrokeDiameter={setEraserStrokeDiameter}
                                            setMenuVisibility={setMenuVisibility}
                                        />
                                    )
                                }
                                {
                                    currentMenu === EditorMenu.LAYER && (
                                        <LayerMenu
                                            setMenuVisibility={setMenuVisibility}
                                            currentDrawLayer={currentDrawLayer}
                                            foregroundVisibility={foregroundVisibility}
                                            middlegroundVisibility={middlegroundVisibility}
                                            backgroundVisibility={backgroundVisibility}
                                            setBackgroundVisibility={setBackgroundVisibility}
                                            setMiddlegroundVisibility={setMiddlegroundVisibility}
                                            setForegroundVisibility={setForegroundVisibility}
                                        />
                                    )
                                }
                                {
                                    currentMenu === EditorMenu.FRAME && (
                                        <FrameMenu
                                            backwardOnionSkin={backwardOnionSkin}
                                            forwardOnionSkin={forwardOnionSkin}
                                            onionSkinNaturalColours={onionSkinNaturalColours}
                                            backwardOnionSkinColour={backwardOnionSkinColour}
                                            forwardOnionSkinColour={forwardOnionSkinColour}
                                            loop={loop}
                                            currentPaperColour={currentPaperColour}
                                            setMenuVisibility={setMenuVisibility}
                                            setCurrentPaperColour={setCurrentPaperColour}
                                            setBackwardOnionSkin={setBackwardOnionSkin}
                                            setForwardOnionSkin={setForwardOnionSkin}
                                            setOnionSkinNaturalColours={setOnionSkinNaturalColours}
                                            setBackwardOnionSkinColour={setBackwardOnionSkinColour}
                                            setForwardOnionSkinColour={setForwardOnionSkinColour}
                                        />
                                    )
                                }
                                {
                                    currentMenu === EditorMenu.SELECT && (
                                        <SelectMenu
                                            setMenuVisibility={setMenuVisibility}
                                        />
                                    )
                                }
                                {
                                    currentMenu === EditorMenu.DEVELOPER && (
                                        <DeveloperMenu
                                            setMenuVisibility={setMenuVisibility}
                                        />
                                    )
                                }
                                {
                                    currentMenu === EditorMenu.HAND && (
                                        <HandMenu
                                            setMenuVisibility={setMenuVisibility}
                                        />
                                    )
                                }
                                {
                                    currentMenu === EditorMenu.SETTINGS && (
                                        <SettingsMenu
                                            setMenuVisibility={setMenuVisibility}
                                        />
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}
