import { faArrowLeft, faArrowRight, faMinus, faPlus, faTrash, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getEditorService } from "../EditorService";
import EditorButton from "../components/EditorButton";
import { getEditorController } from "../EditorController";
import { MAX_ANIMATION_FRAME_COUNT, MAX_FPS, MIN_ANIMATION_FRAME_COUNT, MIN_FPS } from "../Constants";
import ColourInput from "../components/ColourInput";
import { hexToColour } from "../EditorUtils";
import ToggleInput from "../components/ToggleInput";

interface FrameMenuProps {
    loop: boolean,
    currentPaperColour: string,
    backwardOnionSkin: boolean,
    forwardOnionSkin: boolean,
    onionSkinNaturalColours: boolean,
    backwardOnionSkinColour: string,
    forwardOnionSkinColour: string,
    setMenuVisibility: (a: boolean) => void,
    setCurrentPaperColour: React.Dispatch<React.SetStateAction<string>>,
    setBackwardOnionSkin: React.Dispatch<React.SetStateAction<boolean>>,
    setForwardOnionSkin: React.Dispatch<React.SetStateAction<boolean>>,
    setOnionSkinNaturalColours: React.Dispatch<React.SetStateAction<boolean>>,
    setBackwardOnionSkinColour: React.Dispatch<React.SetStateAction<string>>,
    setForwardOnionSkinColour: React.Dispatch<React.SetStateAction<string>>,
}

export default function FrameMenu({
    backwardOnionSkin,
    forwardOnionSkin,
    onionSkinNaturalColours,
    backwardOnionSkinColour,
    forwardOnionSkinColour,
    currentPaperColour,
    setCurrentPaperColour,
    setMenuVisibility,
    setBackwardOnionSkin,
    setForwardOnionSkin,
    setOnionSkinNaturalColours,
    setBackwardOnionSkinColour,
    setForwardOnionSkinColour,
    loop,
}: FrameMenuProps) {
    const backwardShortcut = "Shortcut: o";
    const forwardShortcut = "Shortcut: shift + o";

    return (
        <div className="p-2 lg:p-4 w-full">
            <div className="flex flex-row">
                <div className="w-1/2 flex justify-start">
                    <h1 className="text-3xl">Frame</h1>
                </div>
                <div className="w-1/2 flex justify-end">
                    <button onClick={() => setMenuVisibility(false)}>
                        <FontAwesomeIcon className="text-2xl fa-fw" icon={faX} />
                    </button>
                </div>
            </div>

            <h2 className="text-xl mt-5">Paper Colour</h2>
            <p className="text-sm">Set the colour of the background in this frame for everyone.</p>
            <div className="-ml-2 mt-2">
                <ColourInput
                    value={currentPaperColour}
                    setValue={setCurrentPaperColour}
                    onChange={(e) => {
                        getEditorController().setFramePaperColour(hexToColour(e?.target?.value ?? "#00ff00"));
                    }}
                />
            </div>

            <h2 className="text-xl mt-5">Onion Skin</h2>
            <p className="text-sm">Show neighbouring frames in the current frame just for you.</p>

            <ToggleInput
                label="Backward"
                value={backwardOnionSkin}
                setValue={setBackwardOnionSkin}
                onChange={() => { getEditorController().toggleBackwardOnionSkin() }}
                uncheckedHelpText={backwardShortcut}
                checkedHelpText={backwardShortcut}
            />

            <ToggleInput
                label="Forward"
                value={forwardOnionSkin}
                setValue={setForwardOnionSkin}
                onChange={() => { getEditorController().toggleForwardOnionSkin() }}
                uncheckedHelpText={forwardShortcut}
                checkedHelpText={forwardShortcut}
            />

            <ToggleInput
                label="Natural Colours"
                value={onionSkinNaturalColours}
                setValue={setOnionSkinNaturalColours}
                onChange={() => { getEditorController().toggleOnionSkinNaturalColours() }}
                checkedHelpText="Onion skins use colours from their respective frames."
                uncheckedHelpText="Onion skins use the editable colours below for backward and forward respectively."
            />

            { !onionSkinNaturalColours && (<div className="-mx-2">
                <ColourInput
                    value={backwardOnionSkinColour}
                    setValue={setBackwardOnionSkinColour}
                    onChange={(e) => { getEditorController().setBackwardOnionSkinColour(hexToColour(e.target.value)) }}
                />

                <ColourInput
                    value={forwardOnionSkinColour}
                    setValue={setForwardOnionSkinColour}
                    onChange={(e) => { getEditorController().setForwardOnionSkinColour(hexToColour(e.target.value)) }}
                />
            </div> )}

            { loop && (<p className="text-sm">When loop is enabled, onion skin will wrap around to the beginning or end of the animation.</p> )}

            <h2 className="text-xl mt-5">Frame Rate</h2>
            <p className="text-sm">
                Increase or decrease the frame rate for the whole animation for everyone.
            </p>

            <div className="flex flex-row">
                <EditorButton
                    disabled={getEditorService().getAnimation().getFramesPerSecond() === MIN_FPS}
                    icon={faMinus}
                    shortcutText="j"
                    onClick={() => { getEditorController().decreaseFps() }}
                />

                <EditorButton
                    disabled={getEditorService().getAnimation().getFramesPerSecond() === MAX_FPS}
                    icon={faPlus}
                    shortcutText="k"
                    onClick={() => { getEditorController().increaseFps() }}
                />
            </div>

            <h2 className="text-xl mt-5">Insert and Delete</h2>
            <p className="text-sm">Add and remove frames from the animation for everyone.</p>

            <div>
                <EditorButton
                    text="Add Frame After"
                    shortcutText="i"
                    disabled={getEditorService().getAnimation().getFrameCount() === MAX_ANIMATION_FRAME_COUNT}
                    icon={faArrowRight}
                    onClick={() => { getEditorController().insertFrame(false) }}
                />
            </div>

            <div>
                <EditorButton
                    text="Add Frame Before"
                    shortcutText="shift + i"
                    disabled={getEditorService().getAnimation().getFrameCount() === MAX_ANIMATION_FRAME_COUNT}
                    icon={faArrowLeft}
                    onClick={() => { getEditorController().insertFrame(true) }}
                />
            </div>

            <div>
                <EditorButton
                    text="Delete Frame"
                    shortcutText="shift + backspace"
                    disabled={getEditorService().getAnimation().getFrameCount() === MIN_ANIMATION_FRAME_COUNT}
                    icon={faTrash}
                    onClick={() => { getEditorController().deleteCurrentFrame() }}
                />
                <p className="text-sm font-bold underline">Frame deletion is currently irreversible.</p>
            </div>

            <h2 className="text-2xl mt-10">Playback Shortcuts</h2>
            <p className="text-sm">These are the shortcuts for the actions available in the playback bar.</p>

            <h2 className="text-xl mt-5">Play / Pause</h2>
            <p className="text-sm text-slate-300" >Shortcut: space</p>

            <h2 className="text-xl mt-5">Previous Frame</h2>
            <p className="text-sm text-slate-300" >Shortcut: left, a</p>

            <h2 className="text-xl mt-5">Next Frame</h2>
            <p className="text-sm text-slate-300" >Shortcut: right, d</p>
            <p className="text-sm">Adds a new frame if you're at the end of the animation.</p>
        </div>
    );
}