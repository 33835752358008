import Animation from "../Animation";
import { ColourPalette, WebGL2Colour } from "../Constants";
import Frame from "../Frame";
import { CommandType } from "../commands/Commands";
import { TypedMessage } from "./Messages";

export interface FrameInsertMessage extends TypedMessage {
    type: CommandType.FRAME_INSERT;
    frameId?: number;
    colourPalette?: ColourPalette,
    paperColour?: WebGL2Colour,
}

export function createFrameInsertMessage(animation: Animation, frameId?: number, colourPalette?: ColourPalette, paperColour?: WebGL2Colour): [Frame, FrameInsertMessage] | undefined {
    const frame = animation.insertNewFrameBefore(frameId, colourPalette, paperColour);

    if (!frame) {
        return;
    }

    return [
        frame,
        {
            type: CommandType.FRAME_INSERT,
            frameId,
            colourPalette,
            paperColour,
        },
    ];
}

export function handleFrameInsertMessage(animation: Animation, message: FrameInsertMessage): Frame | undefined {
    const { frameId, colourPalette, paperColour } = message;
    return animation.insertNewFrameBefore(frameId, colourPalette, paperColour);
}
