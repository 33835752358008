import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ToolbarButtonProps {
    icon: IconDefinition,
    selected?: boolean,
    menuOpen?: boolean,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    onContextMenu?: React.MouseEventHandler<HTMLButtonElement>,
}

export default function ToolbarButton({ icon, selected, menuOpen, onClick, onContextMenu }: ToolbarButtonProps) {
    const buttonClassName =
        `active:text-primary p-1 mt-1 landscape:mt-0 landscape:ml-1 landscape:p-2 rounded-t-xl landscape:rounded-r-none landscape:rounded-l-xl  ${selected ? "text-primary" : ""} ${menuOpen ? "bg-slate-700" : ""}`
    return (
        <button
            className={buttonClassName}
            onClick={onClick}
            onContextMenu={onContextMenu}
        >
            <FontAwesomeIcon icon={icon} fixedWidth/>
        </button>
    );
}
