import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MAX_STROKE_DIAMETER, MIN_STROKE_DIAMETER } from "../Constants";
import { getEditorService } from "../EditorService";
import { faX } from "@fortawesome/free-solid-svg-icons";
import RangeInput from "../components/RangeInput";

interface EraserMenuProps {
    eraserStrokeDiameter: number,
    setEraserStrokeDiameter: (a: number) => void,
    setMenuVisibility: (a: boolean) => void,
}

const editorState = getEditorService();

export default function EraserMenu({ eraserStrokeDiameter, setEraserStrokeDiameter, setMenuVisibility }: EraserMenuProps) {
    return (
        <div className="p-2 lg:p-4 w-full">
            <div className="flex flex-row">
                <div className="w-1/2 flex justify-start">
                    <h1 className="text-3xl">Eraser</h1>
                </div>
                <div className="w-1/2 flex justify-end">
                    <button onClick={() => setMenuVisibility(false)}>
                        <FontAwesomeIcon className="text-2xl fa-fw" icon={faX} />
                    </button>
                </div>
            </div>

            <h2 className="text-xl mt-5">Stroke Size</h2>
            <p className="text-sm text-slate-300" >Shortcut: up / down, w / s</p>

            <RangeInput
                minValue={MIN_STROKE_DIAMETER}
                maxValue={MAX_STROKE_DIAMETER}
                step={2}
                value={eraserStrokeDiameter}
                onChange={(e) => {
                    // TODO put this as some canvas function to check state.drawing
                    const value = Number(e.target.value);
                    setEraserStrokeDiameter(value);
                    editorState.setEraserStrokeDiameter(value);
                }}
            />

            <p> Size: {eraserStrokeDiameter}</p>
        </div>
    );
}