import React, { useState } from 'react';
import './App.css';
import Session from './pages/Session';
import Editor from './pages/Editor';

export interface SessionData {
  userId: number,
  sessionId: string,
}

function App() {
  const [sessionData, setSessionData] = useState<SessionData | undefined>(undefined);

  return (
    sessionData == null
      ? <Session setSessionData={setSessionData}></Session>
      : <Editor sessionData={sessionData}></Editor>
  );
}

export default App;
