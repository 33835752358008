interface RangeInputProps {
    maxValue: number,
    minValue: number,
    step: number,
    value: number;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export default function RangeInput({
    maxValue,
    minValue,
    step,
    value,
    onChange,
}: RangeInputProps) {
    return (
        <input
            type="range"
            min={minValue}
            max={maxValue}
            step={step}
            className="accent-primary cursor-pointer w-full"
            value={value}
            onChange={onChange}
        ></input>
    );
}
