export enum CommandType {
    COPY = "COPY",
    CUT = "CUT",
    DELETE_SELECTION = "DELETE_SELECTION",
    DRAW_POINT = "DRAW_POINT",
    DRAW_LINE = "DRAW_LINE",
    DRAW_STROKE = "DRAW_STROKE",
    FRAME_INSERT = "FRAME_INSERT",
    FRAME_DELETE = "FRAME_DELETE",
    LOCK_ACCEPT = "LOCK_ACCEPT",
    LOCK_REQUEST = "LOCK_REQUEST",
    PASTE = "PASTE",
    REDO = "REDO",
    SET_FPS = "SET_FPS",
    SET_PALETTE_COLOUR = "SET_PALETTE_COLOUR",
    SET_PAPER_COLOUR = "SET_PAPER_COLOUR",
    STROKE = "STROKE",
    STROKE_START = "STROKE_START",
    STROKE_END = "STROKE_END",
    UNDO = "UNDO",
    UNLOCK = "UNLOCK",
    USER_JOIN = "USER_JOIN",
    USER_QUIT = "USER_QUIT",
}
