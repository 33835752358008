import Animation from "../Animation";
import Frame from "../Frame";
import { CommandType } from "../commands/Commands";
import { TypedMessage } from "./Messages";

export interface FrameDeleteMessage extends TypedMessage {
    type: CommandType.FRAME_DELETE;
    frameId: number;
}

export function createFrameDeleteMessage(animation: Animation, frameId: number): [Frame, FrameDeleteMessage] | undefined {
    const frame = animation.deleteFrame(frameId);

    if (!frame) {
        return;
    }

    return [
        frame,
        {
            type: CommandType.FRAME_DELETE,
            frameId,
        },
    ];
}

export function handleFrameDeleteMessage(animation: Animation, message: FrameDeleteMessage): Frame | undefined {
    const { frameId } = message;
    return animation.deleteFrame(frameId);
}
