import { BYTE_MASK, COLOUR_WHITE, ColourPalette, DEFAULT_COLOUR_PALETTE, FRAME_TEXTURE_HEIGHT, FRAME_TEXTURE_WIDTH, WebGL2Colour } from "./Constants";

export const FRAME_HEADER_BYTE_LENGTH = 20;

export default class Frame {
    private id: number;
    private index: number;
    private data: Uint8Array;
    private colourPalette: ColourPalette;
    private paperColour: WebGL2Colour;

    constructor(
        id: number,
        index: number,
        data?: Uint8Array,
        width = FRAME_TEXTURE_WIDTH,
        height = FRAME_TEXTURE_HEIGHT,
        colourPalette = DEFAULT_COLOUR_PALETTE,
        paperColour = COLOUR_WHITE,
    ) {
        this.id = id;
        this.index = index;
        this.data = data ?? new Uint8Array(new Array(width * height).fill(0));
        this.colourPalette = JSON.parse(JSON.stringify(colourPalette));
        this.paperColour = paperColour;
    }

    getId(): number {
        return this.id;
    }

    getIndex(): number {
        return this.index;   
    }

    getData(): Uint8Array {
        return this.data;
    }

    getColourPalette(): ColourPalette {
        return this.colourPalette;
    }

    getPaperColour(): WebGL2Colour {
        return this.paperColour;
    }

    setColourPalette(colourPalette: ColourPalette): void {
        this.colourPalette = JSON.parse(JSON.stringify(colourPalette));
    }

    setColourPaletteColour(colour: WebGL2Colour, colourPaletteIndex: number): void {
        this.colourPalette[colourPaletteIndex] = JSON.parse(JSON.stringify(colour));
    }

    setIndex(index: number): void {
        this.index = index;
    }

    setPaperColour(paperColour: WebGL2Colour): void {
        this.paperColour = paperColour;
    }

    serialize(): Uint8Array {
        const frameIdLower = this.id & BYTE_MASK;
        const frameIdUpper = (this.id >> 8) & BYTE_MASK;

        const paperColourBytes = new Uint8Array(this.paperColour.slice(0, 3));
        const colourPaletteByteArrays = this.colourPalette.map(colour => new Uint8Array(colour.slice(0, 3)));

        const frameDataWithHeader = new Uint8Array(FRAME_HEADER_BYTE_LENGTH + this.data.length);
        frameDataWithHeader[0] = frameIdLower;
        frameDataWithHeader[1] = frameIdUpper;
        frameDataWithHeader.set(paperColourBytes, 2);

        for (let i = 0; i < 3; i++) {
            frameDataWithHeader.set(colourPaletteByteArrays[i], (i * 3) + 5);
        }

        // Padding for later usage
        for (let i = 14; i < FRAME_HEADER_BYTE_LENGTH; i++) {
            frameDataWithHeader[i] = 0;
        }

        frameDataWithHeader.set(this.data, FRAME_HEADER_BYTE_LENGTH);

        return frameDataWithHeader;
    }

    static deserialize(index: number, data: Uint8Array): Frame | undefined {
        const frameIdLower = data[0];
        const frameIdUpper = data[1];

        const frameId = frameIdLower | (frameIdUpper << 8);
        const paperColour: WebGL2Colour = [data[2], data[3], data[4], 255];

        const colourA: WebGL2Colour = [data[5], data[6], data[7], 255];
        const colourB: WebGL2Colour = [data[8], data[9], data[10], 255];
        const colourC: WebGL2Colour = [data[11], data[12], data[13], 255];
        
        if (!colourA || !colourB || !colourC) {
            return;
        }

        const colourPalette: ColourPalette = [colourA, colourB, colourC];

        return new Frame(frameId, index, data.slice(FRAME_HEADER_BYTE_LENGTH), undefined, undefined, colourPalette, paperColour);
    }
}
