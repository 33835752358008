import { ANISHARE, BYTE_MASK, ColourPalette, DEFAULT_FRAMES_PER_SECOND, DEFAULT_LOOP_VALUE, DEFAULT_REMIXABLE_VALUE, INTERNAL_HEIGHT, INTERNAL_WIDTH, LAYERS_PER_FRAME, LAYER_WIDTH, MAX_ANIMATION_FRAME_COUNT, MIN_ANIMATION_FRAME_COUNT, PIXELS_PER_BYTE, WebGL2Colour } from "./Constants";
import Frame, { FRAME_HEADER_BYTE_LENGTH } from "./Frame";

const ANIMATION_HEADER_BYTE_LENGTH = 50;
export default class Animation {
    private frames: Frame[];
    private frameIdCounter: number;
    private frameIdToIndexMap: Map<number, number>;

    private width: number;
    private height: number;
    private layerCount: number;
    private framesPerSecond: number;

    private loop: boolean;
    private remixable: boolean;

    constructor(
        width = INTERNAL_WIDTH,
        height = INTERNAL_HEIGHT,
        layerCount = LAYERS_PER_FRAME,
        loop = DEFAULT_LOOP_VALUE,
        remixable = DEFAULT_REMIXABLE_VALUE,
        framesPerSecond = DEFAULT_FRAMES_PER_SECOND,
        autoCreateFirstFrame = true,
    ) {
        this.width = width;
        this.height = height;
        this.layerCount = layerCount;
        this.framesPerSecond = framesPerSecond;

        const [frameWidth, frameHeight] = this.getFrameTextureDimensions();
        this.frameIdToIndexMap = new Map<number, number>();

        if (autoCreateFirstFrame) {
            this.frames = [new Frame(0, 0, undefined, frameWidth, frameHeight)];
            this.frameIdToIndexMap.set(0, 0);
            this.frameIdCounter = MIN_ANIMATION_FRAME_COUNT;
        } else {
            this.frames = [];
            this.frameIdCounter = 0;
        }
        
        this.loop = loop;
        this.remixable = remixable;
    }

    insertNewFrameBefore(frameId?: number, colourPalette?: ColourPalette, paperColour?: WebGL2Colour): Frame | undefined {
        if (this.frames.length >= MAX_ANIMATION_FRAME_COUNT) {
            return;
        }

        const frameIndex = frameId != null ? this.frameIdToIndexMap.get(frameId) : this.frames.length;

        if (frameIndex == null) {
            return;
        }

        const newFrameId = this.frameIdCounter;
        const [width, height] = this.getFrameTextureDimensions();
        const newFrame = new Frame(newFrameId, frameIndex, undefined, width, height, colourPalette, paperColour);
        this.frames.splice(frameIndex, 0, newFrame);
        this.frameIdToIndexMap.set(newFrameId, frameIndex);
        this.frameIdCounter++;

        for (let i = frameIndex + 1; i < this.frames.length; i++) {
            const currentFrameId = this.frames[i].getId();
            this.frames[i].setIndex(i);
            this.frameIdToIndexMap.set(currentFrameId, i);
        }

        return newFrame;
    }

    importFrames(frameCount: number, animationFileData: Uint8Array): void {
        const [frameTextureWidth, frameTextureHeight] = this.getFrameTextureDimensions();
        const frameDataWithHeaderByteLength = (FRAME_HEADER_BYTE_LENGTH + (frameTextureWidth * frameTextureHeight));
        let maxFrameId = 0;

        for (let i = 0; i < frameCount; i++) {
            const offset = ANIMATION_HEADER_BYTE_LENGTH + i * frameDataWithHeaderByteLength;
            const frame = Frame.deserialize(i, animationFileData.slice(offset, offset + frameDataWithHeaderByteLength));

            if (!frame) {
                return;
            }

            this.frames.splice(frame.getIndex(), 0, frame);
            this.frameIdToIndexMap.set(frame.getId(), frame.getIndex());
            maxFrameId = Math.max(maxFrameId, frame.getId());
        }

        this.frameIdCounter = maxFrameId + 1;
    }

    /*

    Given a valid frameId that maps to a real Frame,
    delete that Frame from the animation, returning the
    next frame that the user should display, (e.g the deleted
    frame was the frame they were currently viewing).

    */
    deleteFrame(frameId?: number): Frame | undefined {
        if (frameId == null) {
            return;
        }

        if (this.frames.length <= MIN_ANIMATION_FRAME_COUNT) {
            return;
        }

        const frameIndex = this.frameIdToIndexMap.get(frameId);
        if (frameIndex == null) {
            return;
        }

        const frame = this.frames[frameIndex];
        const nextFrame = this.getNextFrame(frame.getId());
        const previousFrame = this.getPreviousFrame(frame.getId());

        this.frames.splice(frameIndex, 1);
        this.frameIdToIndexMap.delete(frameId);

        for (let i = frameIndex; i < this.frames.length; i++) {
            const currentFrameId = this.frames[i].getId();
            this.frames[i].setIndex(i);
            this.frameIdToIndexMap.set(currentFrameId, i);
        }

        return nextFrame ? nextFrame : previousFrame;
    }

    getFrameById(frameId: number): Frame | undefined {
        const frameIndex = this.frameIdToIndexMap.get(frameId);
        if (frameIndex != null) {
            return this.frames[frameIndex];
        }
    }

    getNextFrame(frameId: number): Frame | undefined {
        const frameIndex = this.getFrameById(frameId)?.getIndex();
        if (frameIndex != null && frameIndex < this.frames.length - 1) {
            return this.frames[frameIndex + 1];
        }
    }

    getPreviousFrame(frameId: number): Frame | undefined {
        const frameIndex = this.frameIdToIndexMap.get(frameId);
        if (frameIndex == null) {
            return;
        }

        if (frameIndex > 0) {
            return this.frames[frameIndex - 1];
        }
    }

    getFirstFrame(): Frame {
        return this.frames[0];
    }

    getLastFrame(): Frame {
        return this.frames[this.frames.length - 1];
    }

    getFrameCount(): number {
        return this.frames.length;
    }

    getFramesPerSecond(): number {
        return this.framesPerSecond;
    }

    setFramesPerSecond(framesPerSecond: number): void {
        this.framesPerSecond = framesPerSecond;
    }

    getLoop(): boolean {
        return this.loop;
    }

    setLoop(loop: boolean): void {
        this.loop = loop;
    }

    getFrameTextureDimensions(): [number, number] {
        return [(this.width / PIXELS_PER_BYTE) * LAYERS_PER_FRAME, this.height];
    }

    getLayerCount(): number {
        return this.layerCount;
    }
    
    getRemixable(): boolean {
        return this.remixable;
    }

    serialize(): Uint8Array {
        const magicWord = Uint8Array.from(ANISHARE.split("").map(c => (c.charCodeAt(0))));
        const version = 0;

        const widthLower = this.width & BYTE_MASK; 
        const widthUpper = (this.width >> 8) & BYTE_MASK; 

        const heightLower = this.height & BYTE_MASK; 
        const heightUpper = (this.height >> 8) & BYTE_MASK; 

        const frameCountLower = this.frames.length & BYTE_MASK;
        const frameCountUpper = (this.frames.length >> 8) & BYTE_MASK;

        const loopFlag = (this.loop ? 1 : 0);
        const remixableFlag = (this.remixable ? 1 : 0) << 1;

        const flags = loopFlag | remixableFlag;

        const padding = new Uint8Array(32).fill(0);

        const [frameTextureWidth, frameTextureHeight] = this.getFrameTextureDimensions();
        const frameDataWithHeaderByteLength = (FRAME_HEADER_BYTE_LENGTH + (frameTextureWidth * frameTextureHeight));
        const animationFileData = new Uint8Array(ANIMATION_HEADER_BYTE_LENGTH + (frameDataWithHeaderByteLength * this.frames.length));

        animationFileData.set(magicWord);
        animationFileData[8] = version;

        animationFileData[9] = widthLower;
        animationFileData[10] = widthUpper;

        animationFileData[11] = heightLower;
        animationFileData[12] = heightUpper;

        animationFileData[13] = this.layerCount & BYTE_MASK;

        animationFileData[14] = frameCountLower;
        animationFileData[15] = frameCountUpper;

        animationFileData[16] = this.framesPerSecond & BYTE_MASK;
        animationFileData[17] = flags;

        animationFileData.set(padding, 18);

        for (let i = 0; i < this.frames.length; i++) {
            const frameDataWithHeader = this.frames[i].serialize();
            animationFileData.set(frameDataWithHeader, ANIMATION_HEADER_BYTE_LENGTH + (i * frameDataWithHeaderByteLength));
        }

        return animationFileData;
    }

    static deserialize(animationFileData: Uint8Array): Animation | undefined {
        const magicWordBytes = animationFileData.slice(0, 9);
        const version = animationFileData[8];

        const width = animationFileData[9] | animationFileData[10] << 8;
        const height = animationFileData[11] | animationFileData[12] << 8;
        const layerCount = animationFileData[13];

        const frameCount = animationFileData[14] | animationFileData[15] << 8;
        const framesPerSecond = animationFileData[16];

        const flags = animationFileData[17];

        const loop = (flags & 1) !== 0;
        const remixable = ((flags >> 1) & 1) !== 0;

        const padding = animationFileData.slice(18, ANIMATION_HEADER_BYTE_LENGTH);

        const animation = new Animation(width, height, layerCount, loop, remixable, framesPerSecond, false);
        animation.importFrames(frameCount, animationFileData);

        return animation;
    }
};
