import { useState } from "react";
import { PROD_BASE_URL } from "../Editor/Constants";

interface SessionProps {
    setSessionData: any,
}

export default function Session({ setSessionData }: SessionProps) {
    const [errorMessage, setErrorMessage] = useState("");
    const [sessionId, setSessionId] = useState("");

    async function onClick(): Promise<void> {
        const url = process.env.NODE_ENV === "production"
            ? `https://${PROD_BASE_URL}/session`
            : "http://localhost:3002/session"; 

        let response: Response | undefined;

        try {
            response = await fetch(url, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ sessionId: sessionId.substring(0, 6) }),
            });
        } catch (error) {
            setErrorMessage("Can't connect to server, please try again later.");
            return;
        }

        const body = await response.json();
        setSessionId("");

        if (response.ok) {
            return setSessionData(body);
        }

        setErrorMessage(body.errorMessage);
    }

    return (
        <div className="min-h-screen bg-slate-900 text-white">
            <div className="w-full lg:w-2/3 xl:w-1/2 lg:mx-auto p-5 pt-10 text-lg text-center font-sans">
                <h1 className="text-5xl mb-5">anishare Editor Demo</h1>
                <p>Enter a session ID to join an existing session with someone else, or leave blank to create a new session for others to join.</p>

                <div className="flex flex-col w-1/2 min-w-48 mx-auto my-10">
                    <label htmlFor="sessionId" className="font-bold">Session ID:</label>
                    <input
                        className="text-black my-2.5 h-10 rounded border-2 border-primary"
                        type="text"
                        id="sessionId"
                        name="sessionId"
                        placeholder="(create new session)"
                        value={sessionId}
                        onChange={(e) => setSessionId(e.target.value.toLowerCase())}
                        maxLength={6}
                    >
                    </input>
                    <button className="bg-primary rounded p-2 w-36 mx-auto text-white" onClick={onClick}>Join Session</button>
                </div>

                <p className="italic text-red-500 my-2">{errorMessage}</p>
                <p className="font-bold underline">Note:</p>
                <p>Joining a session, new or existing, starts you with a blank animation, even if others there have already edited. Try to wait until everyone has joined before starting to draw.</p>

                <div className="mt-5">
                    <a className="text-slate-400" href="https://anishare.co">anishare.co</a>
                </div>
            </div>
        </div>
    );
}