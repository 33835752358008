import { BITS_PER_PIXEL, CANVAS_HEIGHT, CANVAS_WIDTH, FRAME_TEXTURE_WIDTH, HORIZONTAL_SCALE, LAYERS_PER_FRAME, PIXELS_PER_BYTE, PIXEL_MASK, VERTICAL_SCALE } from "./Constants";
import { ColourIndex } from "./EditorService";
import Frame from "./Frame";
import { BrushPattern } from "./enums/BrushPattern";

export function drawPoint(
    x: number,
    y: number,
    diameter: number,
    colourPaletteIndex: ColourIndex,
    destinationFrame: Frame,
    destinationLayerIndex: number,
    brushPattern: BrushPattern,
): void {
    const maxX = Math.floor(CANVAS_WIDTH / HORIZONTAL_SCALE);
    const maxY = Math.floor(CANVAS_HEIGHT / VERTICAL_SCALE);

    const radius = Math.floor(diameter / 2);
    if (radius > 0) {
        for (let i = Math.max(0, x - radius); i < Math.min(x + radius, maxX); i++) {
            for (let j = Math.max(0, y - radius); j < Math.min(y + radius, maxY); j++) {
                drawPixelWithPattern(i, j, colourPaletteIndex, destinationFrame, destinationLayerIndex, brushPattern);
            }
        }
    } else {
        drawPixelWithPattern(Math.min(x, maxX), Math.min(y, maxY), colourPaletteIndex, destinationFrame, destinationLayerIndex, brushPattern);
    }
}

function drawPixelWithPattern(
    x: number,
    y: number,
    colourPaletteIndex: number,
    destinationFrame: Frame,
    destinationLayerIndex: number,
    brushPattern: BrushPattern,
): void {
    switch (brushPattern) {
        case BrushPattern.A:
            if ((x + y) % 2 === 0) {
                return;
            }
            break;
        case BrushPattern.B:
            if (x % 2 === 0 || y % 2 === 0) {
                return;
            }
            break;
        case BrushPattern.C:
            if (x % 3 !== 0 || y % 3 !== 0) {
                return;
            }
            break;
        case BrushPattern.D:
            if (x % 2 === 0) {
                return;
            }
            break;
        case BrushPattern.E:
            if (y % 2 === 0) {
                return;
            }
            break;
    }

    drawPixel(x, y, colourPaletteIndex, destinationFrame, destinationLayerIndex);
}

export function copyPointFromSource(
    x: number,
    y: number,
    diameter: number,
    sourceFrameData: Uint8Array,
    sourceLayerIndex: number,
    destinationFrame: Frame,
    destinationLayerIndex: number,
): void {
    const maxX = Math.floor(CANVAS_WIDTH / HORIZONTAL_SCALE);
    const maxY = Math.floor(CANVAS_HEIGHT / VERTICAL_SCALE);

    const radius = Math.floor(diameter / 2);
    if (radius > 0) {
        for (let i = Math.max(0, x - radius); i < Math.min(x + radius, maxX); i++) {
            for (let j = Math.max(0, y - radius); j < Math.min(y + radius, maxY); j++) {
                copyPixelFromSource(i, j, sourceFrameData, sourceLayerIndex, destinationFrame, destinationLayerIndex);
            }
        }
    } else {
        copyPixelFromSource(x, y, sourceFrameData, sourceLayerIndex, destinationFrame, destinationLayerIndex);
    }
}

export function drawLine(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    diameter: number,
    colourPaletteIndex: number,
    destinationFrame: Frame,
    destinationLayerIndex: number,
    brushPattern: BrushPattern
): void {
    if (Math.abs(y2 - y1) < Math.abs(x2 - x1)) {
        if (x1 > x2) {
            drawLineLow(x2, y2, x1, y1, diameter, destinationFrame, destinationLayerIndex, undefined, undefined, colourPaletteIndex, brushPattern);
        } else {
            drawLineLow(x1, y1, x2, y2, diameter, destinationFrame, destinationLayerIndex, undefined, undefined, colourPaletteIndex, brushPattern);
        }
    } else {
        if (y1 > y2) {
            drawLineHigh(x2, y2, x1, y1, diameter, destinationFrame, destinationLayerIndex, undefined, undefined, colourPaletteIndex, brushPattern);
        } else {
            drawLineHigh(x1, y1, x2, y2, diameter, destinationFrame, destinationLayerIndex, undefined, undefined, colourPaletteIndex, brushPattern);
        }
    }
}

export function copyLineFromSource(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    diameter: number,
    sourceFrameData: Uint8Array,
    sourceLayerIndex: number,
    destinationFrame: Frame,
    destinationLayerIndex: number,
): void {
    if (Math.abs(y2 - y1) < Math.abs(x2 - x1)) {
        if (x1 > x2) {
            drawLineLow(x2, y2, x1, y1, diameter, destinationFrame, destinationLayerIndex, sourceFrameData, sourceLayerIndex);
        } else {
            drawLineLow(x1, y1, x2, y2, diameter, destinationFrame, destinationLayerIndex, sourceFrameData, sourceLayerIndex);
        }
    } else {
        if (y1 > y2) {
            drawLineHigh(x2, y2, x1, y1, diameter, destinationFrame, destinationLayerIndex, sourceFrameData, sourceLayerIndex);
        } else {
            drawLineHigh(x1, y1, x2, y2, diameter, destinationFrame, destinationLayerIndex, sourceFrameData, sourceLayerIndex);
        }
    }
}


function drawPixel(
    x: number,
    y: number,
    colourIndex: ColourIndex,
    destinationFrame: Frame,
    destinationLayerIndex: number,
    skipClearColour = false,
): void {
    if (skipClearColour && colourIndex === ColourIndex.CLEAR) {
        return;
    }

    const [ byteIndex, offset ] = getByteIndexAndOffset(x, y, destinationLayerIndex);

    destinationFrame.getData()[byteIndex] &= ~(PIXEL_MASK << offset);
    destinationFrame.getData()[byteIndex] |= (colourIndex << offset);
}

export function copyPixelFromSource(
    x: number,
    y: number,
    sourceFrameData: Uint8Array,
    sourceLayerIndex: number,
    destinationFrame: Frame,
    destinationLayerIndex: number,
    skipClearColour = false,
): void {
    const [ byteIndex, offset ] = getByteIndexAndOffset(x, y, sourceLayerIndex);

    const sourceFramePixelColourPaletteIndex = (sourceFrameData[byteIndex] & (PIXEL_MASK << offset)) >> offset;

    drawPixel(x, y, sourceFramePixelColourPaletteIndex, destinationFrame, destinationLayerIndex, skipClearColour);
}

export function copyPixelFromSourceOffset(
    sourceX: number,
    sourceY: number,
    destinationX: number,
    destinationY: number,
    sourceFrameData: Uint8Array,
    sourceLayerIndex: number,
    destinationFrame: Frame,
    destinationLayerIndex: number,
    skipClearColour: boolean,
): void {
    const [ byteIndex, offset ] = getByteIndexAndOffset(sourceX, sourceY, sourceLayerIndex);
    const sourceFramePixelColourPaletteIndex = (sourceFrameData[byteIndex] & (PIXEL_MASK << offset)) >> offset;

    drawPixel(destinationX, destinationY, sourceFramePixelColourPaletteIndex, destinationFrame, destinationLayerIndex, skipClearColour);
}

function drawLineLow(
    x1: number, 
    y1: number,
    x2: number,
    y2: number, 
    diameter: number,
    destinationFrame: Frame, 
    destinationLayerIndex: number,
    sourceFrameData?: Uint8Array,
    sourceLayerIndex?: number,
    colourPaletteIndex?: number,
    brushPattern?: BrushPattern, 
): void {
    const dx = x2 - x1;
    const dy = y2 - y1 >= 0 ? y2 - y1 : y1 - y2;
    const yi = y2 - y1 >= 0 ? 1 : -1;

    let d = (2 * dy) - dx;
    let y = y1;

    for (let x = x1; x <= x2; x++) {
        if (sourceFrameData && sourceLayerIndex != null) {
            copyPointFromSource(x, y, diameter, sourceFrameData, sourceLayerIndex, destinationFrame, destinationLayerIndex);
        } else if (colourPaletteIndex != null && brushPattern != null) {
            drawPoint(x, y, diameter, colourPaletteIndex, destinationFrame, destinationLayerIndex, brushPattern);
        }

        if (d > 0) {
            y += yi;
            d += 2 * (dy - dx);
        } else {
            d += 2 * dy;
        }
    }
}

function drawLineHigh(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    diameter: number,
    destinationFrame: Frame,
    destinationLayerIndex: number,
    sourceFrameData?: Uint8Array,
    sourceLayerIndex?: number,
    colourPaletteIndex?: number,
    brushPattern?: BrushPattern, 
): void {
    const dy = y2 - y1;
    const dx = x2 - x1 >= 0 ? x2 - x1 : x1 - x2;
    const xi = x2 - x1 >= 0 ? 1 : -1;

    let d = (2 * dx) - dy;
    let x = x1;

    for (let y = y1; y <= y2; y++) {
        if (sourceFrameData && sourceLayerIndex != null) {
            copyPointFromSource(x, y, diameter, sourceFrameData, sourceLayerIndex, destinationFrame, destinationLayerIndex);
        } else if (colourPaletteIndex != null && brushPattern != null) {
            drawPoint(x, y, diameter, colourPaletteIndex, destinationFrame, destinationLayerIndex, brushPattern);
        }

        if (d > 0) {
            x += xi;
            d += 2 * (dx - dy);
        } else {
            d += 2 * dx;
        }
    }
}

function getByteIndexAndOffset(x: number, y: number, layerIndex: number): [number, number] {
    const byteIndex = Math.floor((x / PIXELS_PER_BYTE) + ((FRAME_TEXTURE_WIDTH / LAYERS_PER_FRAME) * layerIndex) + y * FRAME_TEXTURE_WIDTH);
    const offset = (PIXELS_PER_BYTE - (x % PIXELS_PER_BYTE)) * BITS_PER_PIXEL - BITS_PER_PIXEL;

    return [byteIndex, offset];
}
