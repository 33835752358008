import React, { memo, useEffect, useRef, useState } from "react";
import { ShaderType, createProgram, createShader } from "./WebGL2Utils";
import { animationFragmentShaderSource, animationVertexShaderSource, selectToolFragmentShaderSource, selectToolVertexShaderSource } from "./Shaders";
import { CANVAS_HEIGHT, CANVAS_WIDTH, INTERNAL_WIDTH, FRAME_TEXTURE_HEIGHT, FRAME_TEXTURE_WIDTH, COLOUR_WHITE, MIN_STROKE_DIAMETER, MAX_STROKE_DIAMETER, WebGL2Colour, COLOUR_RED, LAYERS_PER_FRAME, HORIZONTAL_SCALE, VERTICAL_SCALE, ColourPalette, DEFAULT_ZOOM, MAX_ZOOM, MIN_ZOOM, DEFAULT_PAN_RATE, DEFAULT_PAN, PROD_BASE_URL } from "./Constants";
import StrokeCommand from "./commands/StrokeCommand";
import { InboundMessage, SetPaletteColourMessage } from "./messages/Messages";
import { CommandType } from "./commands/Commands";
import { handleFrameInsertMessage } from "./messages/FrameInsertMessage";
import { handleFrameDeleteMessage } from "./messages/FrameDeleteMessage";
import PasteCommand from "./commands/PasteCommand";
import { Line, Point, Rectangle } from "./GeneralTypes";
import { SessionData } from "../../App";
import { ColourIndex, EditorState, EditorTool, getEditorService } from "./EditorService";
import { colourToHex } from "./EditorUtils";
import { EditorUIState } from ".";
import { getEditorController } from "./EditorController";
import DeleteSelectionCommand from "./commands/DeleteSelectionCommand";
import { debug } from "console";

export enum EditorStatus {
    DRAWING = "DRAWING",
    PLAYING = "PLAYING",
}

export enum Tool {
    PENCIL = "pencil",
    ERASER = "eraser",
}

export interface CanvasProps {
    width: number,
    height: number,
    setDrawDiameter: any,
    setCurrentDrawLayer: any,
    setLoop: any,
    setUIPlaying: any,
    setTool: any,
    sessionData: SessionData,
    ui: EditorUIState,
}

export interface CanvasState {
    gl: WebGL2RenderingContext | null,
    animationFrameProgram: WebGLProgram | null,
    selectToolProgram: WebGLProgram | null,
    animationFrameVertexArrayObject: WebGLVertexArrayObject | null,
    selectToolVertexArrayObject: WebGLVertexArrayObject | null,
    selectToolPositionAttributeLocation: number | null,
    selectToolPositionBuffer: WebGLBuffer | null,
    animationFrameTexCoordAttributeLocation: number | null,
    animationFrameTexCoordBuffer: WebGLBuffer | null,
    texture: WebGLTexture | null,
    shiftKeyHeld: boolean,
    controlKeyHeld: boolean,
}

const state: CanvasState = {
    gl: null,
    animationFrameProgram: null,
    selectToolProgram: null,
    animationFrameVertexArrayObject: null,
    selectToolVertexArrayObject: null,
    selectToolPositionAttributeLocation: null,
    selectToolPositionBuffer: null,
    animationFrameTexCoordAttributeLocation: null,
    animationFrameTexCoordBuffer: null,
    texture: null,
    shiftKeyHeld: false,
    controlKeyHeld: false,
}

const editorService = getEditorService();
const editorController = getEditorController();

let editorUi: EditorUIState | undefined;

export default memo(function Canvas({
    width,
    height,
    ui,
    setTool,
    sessionData
}: CanvasProps) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    setUICurrentFrameIndex = ui.setCurrentFrameIndex;
    editorUi = ui;

    function scaleCanvasDrawInputCoords(x: number, y: number, target: HTMLCanvasElement, canvas: React.RefObject<HTMLCanvasElement>): [number, number] {
        const screenX = x - (canvas.current?.offsetLeft ?? target.offsetLeft);
        const screenY = y - (canvas.current?.offsetTop ?? target.offsetTop);

        const cssScaledWidth = canvas.current?.scrollWidth;
        const cssScaledHeight = canvas.current?.scrollHeight;

        const horizontalScale = CANVAS_WIDTH / (cssScaledWidth ?? CANVAS_WIDTH) / HORIZONTAL_SCALE;
        const verticalScale = CANVAS_HEIGHT / (cssScaledHeight ?? CANVAS_HEIGHT) / VERTICAL_SCALE;

        const { zoom } = editorService.getCanvasViewTransformations();

        const scaledX = Math.max(0, Math.floor((screenX * horizontalScale) / zoom));
        const scaledY = Math.max(0, Math.floor((screenY * verticalScale) / zoom));

        return [scaledX, scaledY];
    }

    function clampCanvasMouseEventCoords(pageX: number, pageY: number): [number, number] | undefined{
        const canvasX = canvasRef.current?.offsetLeft;
        const canvasY = canvasRef.current?.offsetTop;
        const canvasWidth = canvasRef.current?.scrollWidth;
        const canvasHeight = canvasRef.current?.scrollHeight;

        if (canvasX == null || canvasY == null || canvasWidth == null || canvasHeight == null) {
            return;
        }

        const x = pageX <= canvasX
            ? canvasX + 1
            : pageX > canvasX + canvasWidth
                ? canvasX + canvasWidth
                : pageX;

        const y = pageY <= canvasY
            ? canvasY + 1
            : pageY > canvasY + canvasHeight
                ? canvasY + canvasHeight
                : pageY;

        return [x, y];
    }

    function handleOnMouseDown(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>): void {
        if (e == null) {
            return;
        }

        canvasRef.current?.focus();
        canvasRef.current?.blur();

        e.preventDefault();
        const [x, y] = scaleCanvasDrawInputCoords(e.pageX, e.pageY, (e.target as HTMLCanvasElement), canvasRef);

        const { panX, panY } = editorService.getCanvasViewTransformations();

        const pannedX = x - panX;
        const pannedY = y - panY;

        if (e.button === 0) {
            if (state.shiftKeyHeld) {
                editorController.handlePanStart(x, y);
            }

            const tool = editorService.getCurrentTool();
            if (tool === EditorTool.BRUSH || tool === EditorTool.ERASER) {
                editorController.handleStrokeStart(pannedX, pannedY);
            }

            if (tool === EditorTool.SELECT && editorService.getState() === EditorState.IDLE) {
                editorController.handleSelectStart(x, y);
            }

            if (editorService.getState() === EditorState.SELECTION_IDLE || editorService.getState() === EditorState.TRANSFORMING) {
                editorController.handleTransformStart(x, y);
            }
        } else if (e.button === 2) {
            const prohibitedStates = [
                EditorState.DRAWING,
                EditorState.PLAYING,
                EditorState.SELECTING,
                EditorState.SELECTION_IDLE,
                EditorState.TRANSLATING,
            ];

            if (prohibitedStates.includes(editorService.getState())) {
                return;
            }

            if (state.controlKeyHeld) {
                editorController.zoomOut();
            } else {
                editorController.zoomInAtPoint(pannedX, pannedY);
            }
        }
    }

    function handleOnTouchStart(e: React.TouchEvent<Element>): void {
        if (e == null) {
            return;
        }

        canvasRef.current?.focus();
        canvasRef.current?.blur();

        e.preventDefault();
        const [x, y] = scaleCanvasDrawInputCoords(e.touches[0].pageX, e.touches[0].pageY, (e.target as HTMLCanvasElement), canvasRef);

        const { panX, panY } = editorService.getCanvasViewTransformations();

        const pannedX = x - panX;
        const pannedY = y - panY;

        const tool = editorService.getCurrentTool();
        if (tool === EditorTool.BRUSH || tool === EditorTool.ERASER) {
            editorController.handleStrokeStart(pannedX, pannedY);
        }

        if (tool === EditorTool.SELECT) {
            editorController.handleSelectStart(x, y);
        }

        if (editorService.getState() === EditorState.SELECTION_IDLE || editorService.getState() === EditorState.TRANSFORMING) {
            editorController.handleTransformStart(x, y);
        }
    }

    function handleOnMouseMove(e: React.MouseEvent<Element, MouseEvent>): void {
        if (e == null) {
            return;
        }

        e.preventDefault();

        const result = clampCanvasMouseEventCoords(e.pageX, e.pageY);
        if (!result) {
            return;
        }

        const [ pageX, pageY ] = result;
        const [x, y] = scaleCanvasDrawInputCoords(pageX, pageY, (e.target as HTMLCanvasElement), canvasRef);

        if (state.shiftKeyHeld) {
            editorController.handlePanMove(x, y);
        }

        const { panX, panY } = editorService.getCanvasViewTransformations();

        const pannedX = x - panX;
        const pannedY = y - panY;

        const tool = editorService.getCurrentTool();
        if (tool === EditorTool.BRUSH || tool === EditorTool.ERASER) {
            editorController.handleStrokeMove(pannedX, pannedY);
        }

        if (tool === EditorTool.SELECT && editorService.getState() === EditorState.SELECTING) {
            editorController.handleSelectMove(x, y);
        }

        if (editorService.getState() === EditorState.TRANSLATING) {
            editorController.handleTranslateMove(x, y);
        }
    }

    function handleOnTouchMove(e: React.TouchEvent<Element>): void {
        if (e == null) {
            return;
        }

        if (editorService.getState() === EditorState.IDLE) {
            return;
        }

        e.preventDefault();

        const result = clampCanvasMouseEventCoords(e.touches[0].pageX, e.touches[0].pageY);
        if (!result) {
            return;
        }

        const [ pageX, pageY ] = result;
        const [x, y] = scaleCanvasDrawInputCoords(pageX, pageY, (e.target as HTMLCanvasElement), canvasRef);

        const { panX, panY } = editorService.getCanvasViewTransformations();

        const pannedX = x - panX;
        const pannedY = y - panY;

        const tool = editorService.getCurrentTool();
        if (tool === EditorTool.BRUSH || tool === EditorTool.ERASER) {
            editorController.handleStrokeMove(pannedX, pannedY);
        }

        if (tool === EditorTool.SELECT && editorService.getState() === EditorState.SELECTING) {
            editorController.handleSelectMove(x, y);
        }

        if (editorService.getState() === EditorState.TRANSLATING) {
            editorController.handleTranslateMove(x, y);
        }
    }

    function handleOnTouchEnd(e: TouchEvent): void {
        if (e == null) {
            return;
        }

        if (e.target === canvasRef.current) {
            e.preventDefault();
        }

        const tool = editorService.getCurrentTool();
        if (tool === EditorTool.BRUSH || tool === EditorTool.ERASER) {
            editorController.handleStrokeEnd();
        }

        if (tool === EditorTool.SELECT) {
            if (e.touches[0]) {
                const result = clampCanvasMouseEventCoords(e.touches[0].pageX, e.touches[0].pageY);
                if (!result) {
                    return;
                }

                const [pageX, pageY] = result;
                const [x, y] = scaleCanvasDrawInputCoords(pageX, pageY, (e.target as HTMLCanvasElement), canvasRef);

                editorController.handleSelectEnd(x, y);
            } else {
                const mouseCoords = editorService.getMouseCoords();
                if (mouseCoords) {
                    const [x, y] = mouseCoords;
                    editorController.handleSelectEnd(x, y);
                } else {
                    const lastMouseCoords = editorService.getLastMouseCoords();
                    if (lastMouseCoords) {
                        const [x, y] = lastMouseCoords;
                        editorController.handleSelectEnd(x, y);
                    }
                }
            }
        }

        if (editorService.getState() === EditorState.TRANSLATING) {
            editorController.handleTranslateEnd();
        }
    }

    function handleOnMouseUp(e: MouseEvent): void {
        if (e == null) {
            return;
        }

        if (e.button === 0) {
            if (state.shiftKeyHeld) {
                editorController.handlePanEnd();
                return;
            }

            const tool = editorService.getCurrentTool();
            if (tool === EditorTool.BRUSH || tool === EditorTool.ERASER) {
                editorController.handleStrokeEnd();
            }

            if (tool === EditorTool.SELECT) {
                const result = clampCanvasMouseEventCoords(e.pageX, e.pageY);
                if (!result) {
                    return;
                }

                const [ pageX, pageY ] = result;
                const [x, y] = scaleCanvasDrawInputCoords(pageX, pageY, (e.target as HTMLCanvasElement), canvasRef);
                editorController.handleSelectEnd(x, y);
            }
            if (editorService.getState() === EditorState.TRANSLATING) {
                editorController.handleTranslateEnd();
            }
        }
    }

    function handleOnKeyDown(e: KeyboardEvent): void {
        if (e == null) {
            return;
        }

        switch (e.key) {
            case "Shift":
                state.shiftKeyHeld = true;
                break;
            case "Control":
                state.controlKeyHeld = true;
                break;
            case "c":
                if (e.ctrlKey || e.metaKey) {
                    editorController.copySelection();
                    return;
                }

                let newColourIndex = editorService.getColourIndex() + 1;
                if (newColourIndex > ColourIndex.C) {
                    newColourIndex = ColourIndex.A;
                }

                setColourIndex(newColourIndex, ui.setCurrentColour);
                break;
            case "x":
                if (e.ctrlKey || e.metaKey) {
                    editorController.cutSelection();
                    return;
                }
                break;
            case "Escape": {
                if ([EditorState.SELECTION_IDLE, EditorState.TRANSFORMING].includes(editorService.getState())) {
                    editorController.deselect();
                }
                break;
            }
            case "t": {
                if (editorService.getState() === EditorState.DRAWING) {
                    return;
                }

                const currentTool = editorService.getCurrentTool();
                let newTool;
                if (currentTool === EditorTool.BRUSH) {
                    newTool = EditorTool.ERASER;
                } else {
                    newTool = EditorTool.BRUSH;
                }

                editorService.setCurrentTool(newTool);
                setTool(newTool);
            } break;
            case "v":
                if (e.ctrlKey || e.metaKey) {
                    editorController.pasteToFloatingLayer();
                    return;
                }
                break;
            case "ArrowDown":
            case "S":
            case "s": {
                e.preventDefault();
                if (editorService.getState() === EditorState.DRAWING) {
                    return;
                }

                if (e.shiftKey){
                    e.preventDefault();
                    editorController.panViewDown();
                    return;
                }

                if (editorService.getCurrentTool() === EditorTool.BRUSH) {
                    const currentDiameter = editorService.getBrushStrokeDiameter();
                    const newDiameter = Math.max(currentDiameter - 2, MIN_STROKE_DIAMETER);

                    editorService.setBrushStrokeDiameter(newDiameter);
                    ui.setBrushStrokeDiameter(newDiameter);
                }

                if (editorService.getCurrentTool() === EditorTool.ERASER) {
                    const currentDiameter = editorService.getEraserStrokeDiameter();
                    const newDiameter = Math.max(currentDiameter - 2, MIN_STROKE_DIAMETER);

                    editorService.setEraserStrokeDiameter(newDiameter);
                    ui.setEraserStrokeDiameter(newDiameter);
                }
            } break;
            case "ArrowUp":
            case "W":
            case "w": {
                e.preventDefault();
                if (editorService.getState() === EditorState.DRAWING) {
                    return;
                }

                if (e.shiftKey){
                    e.preventDefault();
                    editorController.panViewUp();
                    return;
                }

                if (editorService.getCurrentTool() === EditorTool.BRUSH) {
                    const currentDiameter = editorService.getBrushStrokeDiameter();
                    const newDiameter = Math.min(currentDiameter + 2, MAX_STROKE_DIAMETER);

                    editorService.setBrushStrokeDiameter(newDiameter);
                    ui.setBrushStrokeDiameter(newDiameter);
                }

                if (editorService.getCurrentTool() === EditorTool.ERASER) {
                    const currentDiameter = editorService.getEraserStrokeDiameter();
                    const newDiameter = Math.min(currentDiameter + 2, MAX_STROKE_DIAMETER);

                    editorService.setEraserStrokeDiameter(newDiameter);
                    ui.setEraserStrokeDiameter(newDiameter);
                }
            } break;
            case "1": {
                editorController.toggleLayerVisibility(0);
            } break;
            case "2": {
                editorController.toggleLayerVisibility(1);
            } break;
            case "3": {
                editorController.toggleLayerVisibility(2);
            } break;
            case "l":
                const currentIndex = editorService.getCurrentLayerIndex();
                if (currentIndex < LAYERS_PER_FRAME - 1) {
                    editorController.setCurrentLayerIndex(currentIndex + 1);
                } else {
                    editorController.setCurrentLayerIndex(0);
                }
                break;
            case "ArrowLeft":
                if (e.shiftKey) {
                    e.preventDefault();
                    editorController.panViewLeft();
                    return;
                }

                editorController.displayPreviousFrame();
                break;
            case "A":
            case "a": {
                if (e.ctrlKey || e.metaKey) {
                    e.preventDefault();
                    return editorController.selectAll();
                }

                if (e.shiftKey) {
                    editorController.panViewLeft();
                    return;
                }

                editorController.displayPreviousFrame();
            } break;
            case "ArrowRight":
            case "D":
            case "d": {
                if (e.shiftKey) {
                    e.preventDefault();
                    editorController.panViewRight();
                    return;
                }

                editorController.displayNextFrame();
            } break;
            case " ":
                e.preventDefault();
                editorController.togglePlayPause();
                break;
            case "r":
                editorController.resetCanvasViewTransformations();
                renderCurrentCanvasFrame();
                break;
            case "j":
                editorController.decreaseFps();
                break;
            case "k":
                editorController.increaseFps();
                break;
            case "z": {
                if (!(e.ctrlKey || e.metaKey)) {
                    return;
                }

                if (e.shiftKey) {
                    // Command shift z comes under this case
                    editorController.redo();
                    return;
                }

                editorController.undo();
            } break;
            case "Z": {
                if (!(e.ctrlKey || e.metaKey)) {
                    return;
                }

                editorController.redo();
            } break;
            case "i":
            case "I":
                if (e.shiftKey) {
                    editorController.insertFrame(true);
                } else {
                    editorController.insertFrame(false);
                }
                break;
            case "Backspace": {
                if (e.shiftKey) {
                    editorController.deleteCurrentFrame();
                    return;
                }

                editorController.deleteInsideSelection();
            } break;
            case "o": 
            case "O":
            {
                if (e.shiftKey) {
                    editorController.toggleForwardOnionSkin();
                    return;
                }

                editorController.toggleBackwardOnionSkin();
            }
                break;
            case "~":
            case "=":
                ui.setDeveloperModeEnabled(true);
                break;
        }
    }

    function handleOnKeyUp(e: KeyboardEvent): void {
        if (e == null) {
            return;
        }

        switch (e.key) {
            case "Shift":
                state.shiftKeyHeld = false;
                break;
            case "Control":
                state.controlKeyHeld = false;
                break;
        }
    }

    function initializeProgram(
        gl: WebGL2RenderingContext,
        vertexShaderSource: string,
        fragmentShaderSource: string,
    ): WebGLProgram {
        const vertexShader = createShader(gl, ShaderType.VERTEX, vertexShaderSource);
        const fragmentShader = createShader(gl, ShaderType.FRAGMENT, fragmentShaderSource);

        if (!vertexShader || !fragmentShader) {
            throw Error("Vertex or fragment shader could not be created.");
        }

        const program = createProgram(gl, vertexShader, fragmentShader);

        if (!program) {
            throw Error("Program could not be created.");
        }

        return program;
    }

    function initializeAnimationFrameAttributes(gl: WebGL2RenderingContext, animationFrameProgram: WebGLProgram): void {
        const positionAttributeLocation = gl.getAttribLocation(animationFrameProgram, "a_position");
        const positionBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

        const positions = [
            0, 0,
            INTERNAL_WIDTH, 0,
            0, INTERNAL_WIDTH,
            0, INTERNAL_WIDTH,
            INTERNAL_WIDTH, 0,
            INTERNAL_WIDTH, INTERNAL_WIDTH, 
        ];

        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

        const vertexArrayObject = gl.createVertexArray();
        state.animationFrameVertexArrayObject = vertexArrayObject;
        gl.bindVertexArray(vertexArrayObject);
        gl.enableVertexAttribArray(positionAttributeLocation);
        gl.vertexAttribPointer(positionAttributeLocation, 2, gl.FLOAT, false, 0, 0);

        state.animationFrameTexCoordAttributeLocation = gl.getAttribLocation(animationFrameProgram, "a_texCoord");
        state.animationFrameTexCoordBuffer = gl.createBuffer();
        setTexCoordsForLayer(0);

        gl.enableVertexAttribArray(state.animationFrameTexCoordAttributeLocation);
        gl.vertexAttribPointer(state.animationFrameTexCoordAttributeLocation, 2, gl.FLOAT, false, 0, 0);

        state.texture = gl.createTexture();
        gl.activeTexture(gl.TEXTURE0 + 0);
        gl.bindTexture(gl.TEXTURE_2D, state.texture);

        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1);

        gl.enable(gl.BLEND);
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    }

    function initializeSelectToolProgramAttributes(gl: WebGL2RenderingContext, selectToolProgram: WebGLProgram): void {
        state.selectToolPositionAttributeLocation = gl.getAttribLocation(selectToolProgram, "a_position");
        state.selectToolPositionBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, state.selectToolPositionBuffer);

        const positions = [
            0, 0,
            0, 0,
            0, 0,
            0, 0,
            0, 0,
            0, 0,
            0, 0,
            0, 0,
        ];

        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STREAM_DRAW);

        const vertexArrayObject = gl.createVertexArray();
        state.selectToolVertexArrayObject = vertexArrayObject;
        gl.bindVertexArray(vertexArrayObject);
        gl.enableVertexAttribArray(state.selectToolPositionAttributeLocation);
        gl.vertexAttribPointer(state.selectToolPositionAttributeLocation, 2, gl.FLOAT, false, 0, 0);
    }

    function main(gl: WebGL2RenderingContext): void {
        state.animationFrameProgram = initializeProgram(gl, animationVertexShaderSource, animationFragmentShaderSource);
        state.selectToolProgram = initializeProgram(gl, selectToolVertexShaderSource, selectToolFragmentShaderSource);

        initializeAnimationFrameAttributes(gl, state.animationFrameProgram);
        initializeSelectToolProgramAttributes(gl, state.selectToolProgram);

        renderCurrentCanvasFrame();
    }

    const [ webGL2Context, setWebGL2Context ] = useState<WebGL2RenderingContext | null>(null);
    useEffect(() => {
        if (canvasRef.current == null) {
            return;
        }

        if (!state.gl) {
            state.gl = canvasRef.current.getContext('webgl2');
        }

        if (!state.gl) {
            return;
        } else {
            setWebGL2Context(state.gl);
            main(state.gl);
        }

        editorService.setUserId(sessionData.userId);

        if (!editorService.getWebSocket()) {
            const url = process.env.NODE_ENV === "production"
                ? `wss://${PROD_BASE_URL}/websocket?sessionId=${sessionData.sessionId}&userId=${sessionData.userId}`
                : `ws://localhost:3002/websocket?sessionId=${sessionData.sessionId}&userId=${sessionData.userId}`; 
            const webSocket = new WebSocket(url);
            editorService.setWebSocket(webSocket);

            webSocket.addEventListener("message", (e: MessageEvent) => {
                if (e == null) {
                    return;
                }

                try {
                    const message: InboundMessage = JSON.parse(e.data);

                    switch (message.type) {
                        case CommandType.FRAME_INSERT: {
                            handleFrameInsertMessage(editorService.getAnimation(), message);
                            const currentFrameIndex = editorService.getAnimation().getFrameById(editorService.getCurrentFrameId())?.getIndex();
                            if (currentFrameIndex != null) {
                                ui.setCurrentFrameIndex(currentFrameIndex);
                                ui.setFrameCount(editorService.getAnimation().getFrameCount());
                            }
                        } break;

                        case CommandType.FRAME_DELETE: {
                            const { frameId } = message;
                            const fallbackFrame = handleFrameDeleteMessage(editorService.getAnimation(), message);
                            ui.setFrameCount(editorService.getAnimation().getFrameCount());

                            if (frameId === editorService.getCurrentFrameId() && fallbackFrame) {
                                editorController.showFrameById(fallbackFrame.getId());
                            }
                        } break;

                        case CommandType.UNDO: {
                            const { userId } = message;
                            const collaboratorEditHistoryController = editorService.getCollaboratorEditHistoryController(userId);
                            const frameId = collaboratorEditHistoryController?.undo();
                            if (frameId === editorService.getCurrentFrameId()) {
                                renderCurrentCanvasFrame();
                            }
                        } break;

                        case CommandType.REDO: {
                            const { userId } = message;
                            const collaboratorEditHistoryController = editorService.getCollaboratorEditHistoryController(userId);
                            const frameId = collaboratorEditHistoryController?.redo();
                            if (frameId === editorService.getCurrentFrameId()) {
                                renderCurrentCanvasFrame();
                            }
                        } break;

                        case CommandType.STROKE_START: {
                            const { userId } = message;
                            const collaboratorCurrentStroke = editorService.getCollaboratorCurrentStroke(userId);
                            const strokeStartCommand = StrokeCommand.deserialize(message, editorService.getAnimation());
                            if (!collaboratorCurrentStroke && strokeStartCommand) {
                                editorService.setCollaboratorCurrentStroke(userId, strokeStartCommand);
                            }
                        } break;

                        case CommandType.STROKE_END: {
                            const { userId } = message;
                            const collaboratorCurrentStroke = editorService.getCollaboratorCurrentStroke(userId);
                            const collaboratorEditHistoryController = editorService.getCollaboratorEditHistoryController(userId);

                            if (collaboratorCurrentStroke && collaboratorEditHistoryController) {
                                collaboratorEditHistoryController.addCommand(collaboratorCurrentStroke);
                                editorService.setCollaboratorCurrentStroke(userId, undefined);
                            }
                        } break;

                        case CommandType.COPY: {
                            const { userId } = message;
                            const collaboratorClipboard = editorService.getCollaboratorClipboard(userId);

                            if (!collaboratorClipboard) {
                                return;
                            }

                            collaboratorClipboard.handleCopyMessage(message, editorService.getAnimation());
                        } break;

                        case CommandType.PASTE: {
                            const { frameId, userId } = message;
                            const collaboratorClipboard = editorService.getCollaboratorClipboard(userId);

                            if (!collaboratorClipboard) {
                                return;
                            }

                            const collaboratorEditHistoryController = editorService.getCollaboratorEditHistoryController(userId);

                            if (!collaboratorEditHistoryController) {
                                return;
                            }

                            const pasteCommand = PasteCommand.deserialize(message, editorService.getAnimation(), collaboratorClipboard);

                            if (pasteCommand) {
                                pasteCommand.execute();
                                collaboratorEditHistoryController.addCommand(pasteCommand);

                                if (frameId === editorService.getCurrentFrameId()) {
                                    renderCurrentCanvasFrame();
                                }
                            }

                        } break;
                        case CommandType.SET_FPS: {
                            const { fps } = message;
                            editorController.setFps(fps, false);
                        } break;
                        case CommandType.SET_PALETTE_COLOUR: {
                            const { frameId, colourIndex, colour } = message;
                            editorService.getAnimation().getFrameById(frameId)?.setColourPaletteColour(colour, colourIndex);
                            if (frameId === editorService.getCurrentFrameId()) {
                                switch (colourIndex) {
                                    case 0:
                                        ui.setColourA(colourToHex(colour));
                                        break;
                                    case 1:
                                        ui.setColourB(colourToHex(colour));
                                        break;
                                    case 2:
                                        ui.setColourC(colourToHex(colour));
                                        break;
                                }

                                if (editorService.getColourIndex() - 1 === colourIndex) {
                                    ui.setCurrentColour(colour);
                                }
                            }

                            renderCurrentCanvasFrame();
                        } break;
                        case CommandType.USER_JOIN: {
                            const { userId } = message;
                            editorService.addCollaborator(userId);
                        } break;
                        case CommandType.USER_QUIT: {
                            const { userId } = message;
                            editorService.removeCollaborator(userId);
                        } break;
                        case CommandType.DELETE_SELECTION: {
                            const { userId, frameId } = message;

                            const collaboratorEditHistoryController = editorService.getCollaboratorEditHistoryController(userId);

                            if (!collaboratorEditHistoryController) {
                                return;
                            }

                            const deleteSelectionCommand = DeleteSelectionCommand.deserialize(message, editorService.getAnimation());

                            if (deleteSelectionCommand) {
                                deleteSelectionCommand.execute();
                                collaboratorEditHistoryController.addCommand(deleteSelectionCommand);

                                if (frameId === editorService.getCurrentFrameId()) {
                                    renderCurrentCanvasFrame();
                                }
                            }
                        } break;
                        case CommandType.SET_PAPER_COLOUR: {
                            const { frameId, colour } = message;
                            editorController.setFramePaperColour(colour, frameId, false);
                        } break;

                        case CommandType.DRAW_POINT: {
                            const { userId: collaboratorId, x, y } = message;
                            const collaboratorCurrentStroke = editorService.getCollaboratorCurrentStroke(collaboratorId);

                            if (!collaboratorCurrentStroke) {
                                return;
                            }

                            const point: Point = [x, y];
                            collaboratorCurrentStroke.addSubStroke(point);
                            collaboratorCurrentStroke.drawSubStroke(point);

                            if (editorService.getCurrentFrameId() === collaboratorCurrentStroke.getFrameId()) {
                                renderCurrentCanvasFrame();
                            }
                        } break;

                        case CommandType.DRAW_LINE: {
                            const { userId: collaboratorId, x1, y1, x2, y2 } = message;
                            const collaboratorCurrentStroke = editorService.getCollaboratorCurrentStroke(collaboratorId);

                            if (!collaboratorCurrentStroke) {
                                return;
                            }

                            const line: Line = [x1, y1, x2, y2];
                            collaboratorCurrentStroke.addSubStroke(line);
                            collaboratorCurrentStroke.drawSubStroke(line);

                            if (editorService.getCurrentFrameId() === collaboratorCurrentStroke.getFrameId()) {
                                renderCurrentCanvasFrame();
                            }
                        } break;
                    }
                } catch (error: unknown) {
                    console.log(e);
                }
            });
        }

        document.addEventListener("mouseup", handleOnMouseUp);
        document.addEventListener("touchend", handleOnTouchEnd);
        document.addEventListener("touchcancel", handleOnTouchEnd);
        document.addEventListener("keydown", handleOnKeyDown);
        document.addEventListener("keyup", handleOnKeyUp);

        return () => {
            document.removeEventListener("mouseup", handleOnMouseUp);
            document.removeEventListener("keydown", handleOnKeyDown);
            document.removeEventListener("touchcancel", handleOnTouchEnd);
            document.removeEventListener("touchend", handleOnTouchEnd);
            document.removeEventListener("keyup", handleOnKeyUp);
        }
    }, []);

    return (
        <canvas
            id="editor-canvas"
            className="aspect-square w-full touch-none select-none border border-dashed border-white"
            width={width}
            height={height}
            tabIndex={0}
            onMouseDown={handleOnMouseDown}
            onTouchStart={handleOnTouchStart}
            onMouseMove={handleOnMouseMove}
            onTouchMove={handleOnTouchMove}
            onContextMenu={(e) => { e.preventDefault() }}
            ref={canvasRef}
        >
            <a href='https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas#browser_compatibility'>
                Your browser does not support the HTML5 Canvas tag.
            </a>.
        </canvas>
    );
});

function setTexCoordsForLayer(layerIndex: number): void {
    if (!state.gl) {
        return;
    }

    const textureWidth = (FRAME_TEXTURE_WIDTH / LAYERS_PER_FRAME) / FRAME_TEXTURE_WIDTH;
    const textureHeight = 1.0;
    const textureOffset = textureWidth * layerIndex;

    state.gl.bindBuffer(state.gl.ARRAY_BUFFER, state.animationFrameTexCoordBuffer);
    state.gl.bufferData(
        state.gl.ARRAY_BUFFER,
        new Float32Array([
            textureOffset, 0.0,
            textureOffset + textureWidth, 0.0,
            textureOffset, textureHeight,
            textureOffset, textureHeight,
            textureOffset + textureWidth, 0.0,
            textureOffset + textureWidth, textureHeight,
        ]),
        state.gl.STATIC_DRAW,
    );
}

function initializeUniforms(gl: WebGL2RenderingContext, program: WebGLProgram): void {
    const resolutionUniformLocation = gl.getUniformLocation(program, "u_resolution");
    gl.uniform2f(resolutionUniformLocation, gl.canvas.width, gl.canvas.height);

    const horizontalScaleUniformLocation = gl.getUniformLocation(program, "u_horizontalScale");
    gl.uniform1f(horizontalScaleUniformLocation, HORIZONTAL_SCALE);

    const verticalScaleUniformLocation = gl.getUniformLocation(program, "u_verticalScale");
    gl.uniform1f(verticalScaleUniformLocation, VERTICAL_SCALE);

    const { zoom: currentZoom } = editorService.getCanvasViewTransformations();
    const zoomUniformLocation = gl.getUniformLocation(program, "u_zoom");
    const zoom = editorService.getState() === EditorState.PLAYING ? DEFAULT_ZOOM : currentZoom;
    gl.uniform1f(zoomUniformLocation, zoom);

    const textureUniformLocation = gl.getUniformLocation(program, "u_image");
    gl.uniform1i(textureUniformLocation, 0);
}

export function setSelectionDimensions(dimensions: Rectangle): void {
    if (!state.gl || !state.selectToolProgram) {
        return;
    }

    const { x, y, width, height } = dimensions;

    const positions = [
        x, y,
        x + width, y,
        x + width, y,
        x + width, y + height,
        x + width, y + height,
        x, y + height,
        x, y + height,
        x, y,
    ];

    state.gl.useProgram(state.selectToolProgram);
    state.gl.bindVertexArray(state.selectToolVertexArrayObject);
    state.gl.bindBuffer(state.gl.ARRAY_BUFFER, state.selectToolPositionBuffer);
    state.gl.bufferData(state.gl.ARRAY_BUFFER, new Float32Array(positions), state.gl.STREAM_DRAW);
}

function setAnimationProgramColourPalette(gl: WebGL2RenderingContext, colourPalette: ColourPalette, uniformLocations: (WebGLUniformLocation | null)[]): void {
    for (let i = 0; i < colourPalette.length; i++) {
        gl.uniform4fv(uniformLocations[i], colourPalette[i]);
    }
}

function getCanvasPan(): [number, number] {
    if (editorService.getState() === EditorState.PLAYING) {
        return [DEFAULT_PAN, DEFAULT_PAN];
    }

    const { panX, panY } = editorService.getCanvasViewTransformations();
    return [panX, panY];
}

function getCanvasFloatingFrameOffset(): [number, number] {
    const { panX, panY } = editorService.getCanvasViewTransformations();
    const [ offsetX, offsetY ] = editorService.getFloatingFrameOffset();

    return [offsetX + panX, offsetY + panY];
}

export function renderCurrentCanvasFrame(): void {
    if (!state.gl || !state.animationFrameProgram) {
        return;
    }

    state.gl.useProgram(state.animationFrameProgram);
    state.gl.bindVertexArray(state.animationFrameVertexArrayObject);
    state.gl.viewport(0, 0, state.gl.canvas.width, state.gl.canvas.height);

    const currentPaperColour = editorService.getAnimation().getFrameById(editorService.getCurrentFrameId())?.getPaperColour();
    const clearColour = currentPaperColour ? currentPaperColour : COLOUR_WHITE;

    const [r, g, b, a] = clearColour.map(value => value / 255.0);
    state.gl.clearColor(r, g, b, a);
    state.gl.clear(state.gl.COLOR_BUFFER_BIT);

    initializeUniforms(state.gl, state.animationFrameProgram);

    const offsetUniformLocation = state.gl.getUniformLocation(state.animationFrameProgram, "u_offset");
    state.gl.uniform2fv(offsetUniformLocation, getCanvasPan());

    const opacityUniformLocation = state.gl.getUniformLocation(state.animationFrameProgram, "u_opacity");

    const [backgroundVisible, middlegroundVisible, foregroundVisible] = editorService.getLayerVisibility();

    const colours0UniformLocation = state.gl.getUniformLocation(state.animationFrameProgram, "u_colours[0]");
    const colours1UniformLocation = state.gl.getUniformLocation(state.animationFrameProgram, "u_colours[1]");
    const colours2UniformLocation = state.gl.getUniformLocation(state.animationFrameProgram, "u_colours[2]");
    
    const colourUniformLocations = [colours0UniformLocation, colours1UniformLocation, colours2UniformLocation];

    const [ backwardOnionSkin, forwardOnionSkin ] = editorService.getOnionSkinSettings();
    const onionSkinNaturalColours = editorService.getOnionSkinColourSetting();

    const currentState = editorService.getState();
    const previousState = editorService.editorStatePeek();

    const previousFrame = editorService.getAnimation().getPreviousFrame(editorService.getCurrentFrameId())
        ?? (editorService.getAnimation().getLoop() ? editorService.getAnimation().getLastFrame() : undefined);
    if (previousFrame && editorService.getState() !== EditorState.PLAYING && backwardOnionSkin) {

        if (onionSkinNaturalColours) {
            setAnimationProgramColourPalette(state.gl, previousFrame.getColourPalette(), colourUniformLocations);
        } else {
            const backwardOnionSkinColour = editorService.getOnionSkinBackwardColour();
            setAnimationProgramColourPalette(
                state.gl,
                [
                    backwardOnionSkinColour,
                    backwardOnionSkinColour,
                    backwardOnionSkinColour,
                ],
                colourUniformLocations,
            );
        }

        state.gl.texImage2D(state.gl.TEXTURE_2D, 0, state.gl.R8UI, FRAME_TEXTURE_WIDTH, FRAME_TEXTURE_HEIGHT, 0, state.gl.RED_INTEGER, state.gl.UNSIGNED_BYTE, previousFrame.getData());
        state.gl.uniform1f(opacityUniformLocation, 0.5);

        if (backgroundVisible) {
            setTexCoordsForLayer(0);
            state.gl.drawArrays(state.gl.TRIANGLES, 0, 6);
        }

        if (middlegroundVisible) {
            setTexCoordsForLayer(1);
            state.gl.drawArrays(state.gl.TRIANGLES, 0, 6);
        }

        if (foregroundVisible) {
            setTexCoordsForLayer(2);
            state.gl.drawArrays(state.gl.TRIANGLES, 0, 6);
        }
    }

    const nextFrame = editorService.getAnimation().getNextFrame(editorService.getCurrentFrameId())
        ?? (editorService.getAnimation().getLoop() ? editorService.getAnimation().getFirstFrame() : undefined);
    if (nextFrame && editorService.getState() !== EditorState.PLAYING && forwardOnionSkin) {

        if (onionSkinNaturalColours) {
            setAnimationProgramColourPalette(state.gl, nextFrame.getColourPalette(), colourUniformLocations);
        } else {
            const forwardOnionSkinColour = editorService.getOnionSkinForwardColour();
            setAnimationProgramColourPalette(
                state.gl,
                [
                    forwardOnionSkinColour,
                    forwardOnionSkinColour,
                    forwardOnionSkinColour,
                ],
                colourUniformLocations,
            );
        }

        state.gl.texImage2D(state.gl.TEXTURE_2D, 0, state.gl.R8UI, FRAME_TEXTURE_WIDTH, FRAME_TEXTURE_HEIGHT, 0, state.gl.RED_INTEGER, state.gl.UNSIGNED_BYTE, nextFrame.getData());
        state.gl.uniform1f(opacityUniformLocation, 0.5);

        if (backgroundVisible) {
            setTexCoordsForLayer(0);
            state.gl.drawArrays(state.gl.TRIANGLES, 0, 6);
        }

        if (middlegroundVisible) {
            setTexCoordsForLayer(1);
            state.gl.drawArrays(state.gl.TRIANGLES, 0, 6);
        }

        if (foregroundVisible) {
            setTexCoordsForLayer(2);
            state.gl.drawArrays(state.gl.TRIANGLES, 0, 6);
        }
    }

    const transformingStates = [
        EditorState.TRANSFORMING,
        EditorState.TRANSLATING,
    ];

    const currentFrameDataTexture = editorService.getAnimation().getFrameById(editorService.getCurrentFrameId())?.getData();
    if (currentFrameDataTexture) {
        const currentColourPalette = editorService.getCurrentColourPalette();
        setAnimationProgramColourPalette(state.gl, currentColourPalette, colourUniformLocations);

        state.gl.texImage2D(state.gl.TEXTURE_2D, 0, state.gl.R8UI, FRAME_TEXTURE_WIDTH, FRAME_TEXTURE_HEIGHT, 0, state.gl.RED_INTEGER, state.gl.UNSIGNED_BYTE, currentFrameDataTexture);

        if (backgroundVisible) {
            setTexCoordsForLayer(0);
            state.gl.uniform1f(opacityUniformLocation, 1.0);
            state.gl.drawArrays(state.gl.TRIANGLES, 0, 6);
        }

        if ((transformingStates.includes(currentState) || transformingStates.includes(previousState)) && editorService.getCurrentLayerIndex() === 0) {
            state.gl.uniform1f(opacityUniformLocation, 0.9);
            state.gl.uniform2fv(offsetUniformLocation, getCanvasFloatingFrameOffset());
            state.gl.texImage2D(state.gl.TEXTURE_2D, 0, state.gl.R8UI, FRAME_TEXTURE_WIDTH, FRAME_TEXTURE_HEIGHT, 0, state.gl.RED_INTEGER, state.gl.UNSIGNED_BYTE, editorService.getFloatingFrame().getData());
            state.gl.drawArrays(state.gl.TRIANGLES, 0, 6);
        }

        if (middlegroundVisible) {
            setTexCoordsForLayer(1);
            state.gl.uniform1f(opacityUniformLocation, 1.0);
            state.gl.texImage2D(state.gl.TEXTURE_2D, 0, state.gl.R8UI, FRAME_TEXTURE_WIDTH, FRAME_TEXTURE_HEIGHT, 0, state.gl.RED_INTEGER, state.gl.UNSIGNED_BYTE, currentFrameDataTexture);
            state.gl.uniform2fv(offsetUniformLocation, getCanvasPan());
            state.gl.drawArrays(state.gl.TRIANGLES, 0, 6);
        }

        if ((transformingStates.includes(currentState) || transformingStates.includes(previousState)) && editorService.getCurrentLayerIndex() === 1) {
            state.gl.uniform1f(opacityUniformLocation, 0.9);
            state.gl.uniform2fv(offsetUniformLocation, getCanvasFloatingFrameOffset());
            state.gl.texImage2D(state.gl.TEXTURE_2D, 0, state.gl.R8UI, FRAME_TEXTURE_WIDTH, FRAME_TEXTURE_HEIGHT, 0, state.gl.RED_INTEGER, state.gl.UNSIGNED_BYTE, editorService.getFloatingFrame().getData());
            state.gl.drawArrays(state.gl.TRIANGLES, 0, 6);
        }

        if (foregroundVisible) {
            setTexCoordsForLayer(2);
            state.gl.uniform1f(opacityUniformLocation, 1.0);
            state.gl.texImage2D(state.gl.TEXTURE_2D, 0, state.gl.R8UI, FRAME_TEXTURE_WIDTH, FRAME_TEXTURE_HEIGHT, 0, state.gl.RED_INTEGER, state.gl.UNSIGNED_BYTE, currentFrameDataTexture);
            state.gl.uniform2fv(offsetUniformLocation, getCanvasPan());
            state.gl.drawArrays(state.gl.TRIANGLES, 0, 6);
        }

        if ((transformingStates.includes(currentState) || transformingStates.includes(previousState)) && editorService.getCurrentLayerIndex() === 2) {
            state.gl.uniform1f(opacityUniformLocation, 0.9);
            state.gl.uniform2fv(offsetUniformLocation, getCanvasFloatingFrameOffset());
            state.gl.texImage2D(state.gl.TEXTURE_2D, 0, state.gl.R8UI, FRAME_TEXTURE_WIDTH, FRAME_TEXTURE_HEIGHT, 0, state.gl.RED_INTEGER, state.gl.UNSIGNED_BYTE, editorService.getFloatingFrame().getData());
            state.gl.drawArrays(state.gl.TRIANGLES, 0, 6);
        }
    }

    const selectionStates = [
        EditorState.SELECTING,
        EditorState.SELECTION_IDLE,
        EditorState.TRANSFORMING,
        EditorState.TRANSLATING,
    ];

    if (selectionStates.includes(currentState) || selectionStates.includes(previousState)) {
        state.gl.useProgram(state.selectToolProgram);

        if (state.selectToolProgram) {
            const resolutionUniformLocation = state.gl.getUniformLocation(state.selectToolProgram, "u_resolution");
            state.gl.uniform2f(resolutionUniformLocation, state.gl.canvas.width, state.gl.canvas.height);

            const horizontalScaleUniformLocation = state.gl.getUniformLocation(state.selectToolProgram, "u_horizontalScale");
            state.gl.uniform1f(horizontalScaleUniformLocation, HORIZONTAL_SCALE);

            const verticalScaleUniformLocation = state.gl.getUniformLocation(state.selectToolProgram, "u_verticalScale");
            state.gl.uniform1f(verticalScaleUniformLocation, VERTICAL_SCALE);

            const zoomUniformLocation = state.gl.getUniformLocation(state.selectToolProgram, "u_zoom");
            const { zoom } = editorService.getCanvasViewTransformations();
            state.gl.uniform1f(zoomUniformLocation, zoom);

            const transformingStates = [
                EditorState.TRANSFORMING,
                EditorState.TRANSLATING
            ];

            const offsetUniformLocation = state.gl.getUniformLocation(state.selectToolProgram, "u_offset");
            if (transformingStates.includes(currentState) || transformingStates.includes(previousState)) {
                state.gl.uniform2fv(offsetUniformLocation, getCanvasPan());
            } else {
                state.gl.uniform2fv(offsetUniformLocation, [0, 0]);
            }
        }

        state.gl.bindVertexArray(state.selectToolVertexArrayObject);
        state.gl.drawArrays(state.gl.LINES, 0, 8);
    }
}

// Code gods, I'm so sorry...
let setUICurrentFrameIndex: ((a: number) => void) | undefined;
export function updateCanvas(playbackFrameTimestamp: number): void {
    const playbackState = editorService.getPlaybackState();

    const deltaMs = playbackFrameTimestamp - (playbackState.lastFrameTimestamp ?? playbackFrameTimestamp);
    playbackState.lastFrameTimestamp = playbackFrameTimestamp;

    playbackState.timeCounter += deltaMs;
    playbackState.benchmarkTotalTime += deltaMs;

    if (playbackState.timeCounter > (1000 / editorService.getAnimation().getFramesPerSecond()) - 5) {
        playbackState.timeCounter = 0;

        const nextFrame = editorService.getAnimation().getNextFrame(editorService.getCurrentFrameId());
        playbackState.benchmarkFrameCount++;

        if (nextFrame) {
            editorService.setCurrentFrameId(nextFrame.getId());
            if (setUICurrentFrameIndex) {
                setUICurrentFrameIndex(nextFrame.getIndex());
            }

            renderCurrentCanvasFrame();
        } else {
            if (!editorService.getAnimation().getLoop()) {
                editorController.togglePlayPause();
            } else {
                const firstFrame = editorService.getAnimation().getFirstFrame();
                editorService.setCurrentFrameId(firstFrame.getId());
                if (setUICurrentFrameIndex) {
                    setUICurrentFrameIndex(firstFrame.getIndex());
                }
            }

            renderCurrentCanvasFrame();
        }
    }

    if (editorService.getState() === EditorState.PLAYING) {
        playbackState.requestId = requestAnimationFrame(updateCanvas);
    }
}

export function toggleLoop(
    setLoop: (a: boolean) => void,
): void {
    editorService.getAnimation().setLoop(!editorService.getAnimation().getLoop());
    setLoop(editorService.getAnimation().getLoop());
    renderCurrentCanvasFrame();
}

export function setCurrentFrameColourPaletteColour(colour: WebGL2Colour, colourIndex: number, setCurrentColour: any): void {
    editorService.getAnimation().getFrameById(editorService.getCurrentFrameId())?.setColourPaletteColour(colour, colourIndex);
    if (editorService.getColourIndex() - 1 === colourIndex) {
       setCurrentColour(colour);
    }

    const message: SetPaletteColourMessage = {
        type: CommandType.SET_PALETTE_COLOUR,
        colourIndex,
        colour,
        frameId: editorService.getCurrentFrameId(),
    }

    editorController.sendJSONMessage(message);
    renderCurrentCanvasFrame();
}

export function setColourIndex(colourIndex: number, setCurrentColour: any): void {
    if (editorService.getState() === EditorState.DRAWING) {
        return;
    }

    editorService.setColourIndex(colourIndex);
    setCurrentColour(editorService.getCurrentColourPalette()[colourIndex - 1]);
}
