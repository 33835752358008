import { faDownload, faUpload, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditorButton from "../components/EditorButton";
import { getEditorService } from "../EditorService";
import { useRef } from "react";
import { getEditorController } from "../EditorController";
import Animation from ".././Animation";

interface SettingsMenuProps {
    setMenuVisibility: React.Dispatch<React.SetStateAction<boolean>>,
}

function getCurrentTimeString(): string {
  const date = new Date();

  const year = date.getFullYear();
  const month = `${(date.getMonth() + 1).toString().padStart(2, "0")}`;
  const day = `${date.getDate().toString().padStart(2, "0")}`;

  const hours = `${date.getHours().toString().padStart(2, "0")}`;
  const minutes = `${date.getMinutes().toString().padStart(2, "0")}`;
  const seconds = `${date.getSeconds().toString().padStart(2, "0")}`;

  return `${year}${month}${day}-${hours}${minutes}${seconds}`;
}

export default function SettingsMenu({
    setMenuVisibility,
}:SettingsMenuProps) {
    const fileInputRef = useRef<HTMLInputElement>(null);

    return (
        <div className="p-2 lg:p-4 w-full">
            <div className="flex flex-row">
                <div className="w-1/2 flex justify-start">
                    <h1 className="text-3xl">Settings</h1>
                </div>
                <div className="w-1/2 flex justify-end">
                    <button onClick={() => setMenuVisibility(false)}>
                        <FontAwesomeIcon className="text-2xl fa-fw" icon={faX} />
                    </button>
                </div>
            </div>
            <h2 className="text-xl mt-5">Save / Load</h2>
            <p className="text-sm">Upload a .ansh animation file to resume editing, or export the current animation to a .ansh file.</p>

            <input
                className="hidden"
                type="file"
                id="animationFileUploadInput"
                name="animationFileUploadInput"
                accept=".ansh"
                ref={fileInputRef}
                onChange={async (e) => {
                    const files = e.target.files;
                    if (!files) {
                        return;
                    }

                    const animationFile = files[0];
                    const animationFileData = new Uint8Array(await animationFile.arrayBuffer());

                    const animation = Animation.deserialize(animationFileData);
                    if (!animation) {
                        console.log("WUH WOH!");
                        return;
                    }

                    getEditorController().stopPlayback();
                    getEditorService().setAnimation(animation);
                    getEditorController().refreshEditorUI();
                }}
            />

            <div>
                <EditorButton
                    icon={faDownload}
                    text="Save"
                    onClick={async () => {
                        const animation = getEditorService().getAnimation();
                        const serializedAnimation = animation.serialize();

                        const a = document.createElement("a");
                        a.setAttribute("download", `animation-${getCurrentTimeString()}.ansh`);

                        const blob = new Blob([serializedAnimation], { type: "application/octet-stream" });
                        const url = URL.createObjectURL(blob);
                        a.href = url;

                        a.click();
                    }}
                />
                <EditorButton
                    icon={faUpload}
                    text="Load"
                    onClick={() => {
                        fileInputRef.current?.click();
                    }}
                />
            </div>
        </div>
    );
}
