import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface PlaybackButtonProps {
    icon: IconDefinition,
    highlightCondition?: boolean,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
}

export default function PlaybackButton({ icon, highlightCondition, onClick }: PlaybackButtonProps) {
    return (
        <button
            className={"active:text-primary"}
            onClick={onClick}
        >
            <FontAwesomeIcon className={highlightCondition ? "text-white" : "text-gray-600"} icon={icon} fixedWidth/>
        </button>
    );
}
