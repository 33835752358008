import { EditorUIState } from ".";
import { CanvasState, renderCurrentCanvasFrame, setSelectionDimensions, updateCanvas } from "./Canvas";
import { CANVAS_HEIGHT, CANVAS_WIDTH, COLOUR_WHITE, DEFAULT_PAN, DEFAULT_PAN_RATE, DEFAULT_ZOOM, HORIZONTAL_SCALE, MAX_FPS, MAX_ZOOM, MIN_FPS, MIN_ZOOM, VERTICAL_SCALE, WebGL2Colour } from "./Constants";
import { copyPixelFromSource, copyPixelFromSourceOffset } from "./DrawUtils";
import { ColourIndex, EditorState, EditorTool, getEditorService } from "./EditorService";
import { colourToHex } from "./EditorUtils";
import { Line, Point, Rectangle } from "./GeneralTypes";
import { CommandType } from "./commands/Commands";
import DeleteSelectionCommand from "./commands/DeleteSelectionCommand";
import PasteCommand from "./commands/PasteCommand";
import StrokeCommand from "./commands/StrokeCommand";
import { BrushPattern } from "./enums/BrushPattern";
import { createFrameDeleteMessage } from "./messages/FrameDeleteMessage";
import { createFrameInsertMessage } from "./messages/FrameInsertMessage";
import { DrawLineMessage, DrawPointMessage, Message, SetFPSMessage, SetPaperColourMessage, StrokeEndMessage } from "./messages/Messages";

let editorController: EditorController | undefined;
export function getEditorController(): EditorController {
    if (!editorController) {
        editorController = new EditorController();
    }

    return editorController;
}

class EditorController {
    private editorService;
    private editorUIState?: EditorUIState;

    constructor() {
        this.editorService = getEditorService();
    }

    setEditorUIState(editorUIState: EditorUIState): void {
        this.editorUIState = editorUIState;
    }

    setCurrentTool(editorTool: EditorTool): void {
        this.editorService.setCurrentTool(editorTool);
        this.editorUIState?.setTool(editorTool);
    }

    handleStrokeStart(x: number, y: number): void {
        if (this.editorService.getState() !== EditorState.IDLE) {
            return;
        }

        if (!this.editorService.setState(EditorState.DRAWING)) {
            return;
        }

        const currentFrameId = this.editorService.getCurrentFrameId();
        const layerIndex = this.editorService.getCurrentLayerIndex();
        const strokeColour = this.editorService.getCurrentStrokeColour();
        const diameter = this.editorService.getCurrentTool() === EditorTool.BRUSH
            ? this.editorService.getBrushStrokeDiameter()
            : this.editorService.getEraserStrokeDiameter();
        const brushPattern = this.editorService.getCurrentTool() === EditorTool.BRUSH
            ? this.editorService.getBrushPattern()
            : BrushPattern.SOLID;

        const currentFrame = this.editorService.getAnimation().getFrameById(currentFrameId);

        if (!currentFrame) {
            return;
        }

        const strokeCommand = new StrokeCommand(
            diameter,
            strokeColour,
            currentFrame,
            layerIndex,
            brushPattern,
        );

        const point: Point = [x, y];
        strokeCommand.addSubStroke(point);
        strokeCommand.drawSubStroke(point);
        this.editorService.setCurrentStroke(strokeCommand);

        this.sendJSONMessage(strokeCommand.serialize());

        const message: DrawPointMessage = {
            type: CommandType.DRAW_POINT,
            x,
            y,
        };

        this.sendJSONMessage(message);

        this.editorService.setLastMouseCoords(x, y);
        renderCurrentCanvasFrame();
    }

    handleSelectStart(x: number, y: number): void {
        if (this.editorService.getState() !== EditorState.IDLE) {
            return;
        }

        if (!this.editorService.setState(EditorState.SELECTING)) {
            return;
        }

        this.editorService.setLastMouseCoords(x, y);
        renderCurrentCanvasFrame();
    }

    handleTransformStart(x: number, y: number): void {
        if (
            this.editorService.getState() !== EditorState.SELECTION_IDLE &&
            this.editorService.getState() !== EditorState.TRANSFORMING
        ) {
            return;
        }

        const userSelection = this.editorService.getUserSelection();
        if (!userSelection) {
            return;
        }

        const { x: selectionX, y: selectionY, width, height } = userSelection;
        const { panX, panY } = this.editorService.getCanvasViewTransformations();

        const transformedX = this.editorService.getState() === EditorState.TRANSFORMING ? x - panX : x;
        const transformedY = this.editorService.getState() === EditorState.TRANSFORMING ? y - panY : y;

        if (
            transformedX > selectionX && transformedX < selectionX + width
            && transformedY > selectionY && transformedY < selectionY + height 
        ) {
            this.editorService.setLastMouseCoords(x, y);
            this.editorService.setState(EditorState.TRANSLATING);
        } else {
            this.deselect();

            if (this.editorService.getCurrentTool() === EditorTool.SELECT) {
                this.editorService.setState(EditorState.SELECTING);
                this.editorService.setLastMouseCoords(x, y);
            }
        }

        renderCurrentCanvasFrame();
    }

    handlePanStart(x: number, y: number): void {
        this.editorService.editorStatePush(this.editorService.getState());
        if (!this.editorService.setState(EditorState.PANNING)){
            this.editorService.editorStatePop();
            return;
        }

        this.editorService.setLastMouseCoords(x, y);
    }

    handlePanMove(x: number, y: number): void {
        if (this.editorService.getState() !== EditorState.PANNING) {
            return;
        }

        const lastMouseCoords = this.editorService.getLastMouseCoords();
        if (!lastMouseCoords) {
            return;
        }

        const [ lastX, lastY ] = lastMouseCoords;
        const dx = x - lastX;
        const dy = y - lastY;

        const { panX, panY } = this.editorService.getCanvasViewTransformations();
        this.setPan("x", panX + dx);
        this.setPan("y", panY + dy);

        this.editorService.setLastMouseCoords(x, y);
        renderCurrentCanvasFrame();
    }

    handleStrokeMove(x: number, y: number): void {
        if (this.editorService.getState() !== EditorState.DRAWING) {
            return;
        }

        const lastMouseCoords = this.editorService.getLastMouseCoords();

        if (lastMouseCoords) {
            const [lastMouseX, lastMouseY] = lastMouseCoords;
            const line: Line = [lastMouseX, lastMouseY, x, y];

            const currentStroke = this.editorService.getCurrentStroke();
            if (currentStroke) {
                currentStroke.addSubStroke(line);
                currentStroke.drawSubStroke(line);
            }

            const currentFrameId = this.editorService.getCurrentFrameId();

            const destinationFrame = this.editorService.getAnimation().getFrameById(currentFrameId);
            if (destinationFrame) {
                const message: DrawLineMessage = {
                    type: CommandType.DRAW_LINE,
                    x1: lastMouseX,
                    y1: lastMouseY,
                    x2: x,
                    y2: y,
                };

                this.sendJSONMessage(message);
            }
        }
        this.editorService.setLastMouseCoords(x, y);
        renderCurrentCanvasFrame();
    }

    handleSelectMove(x: number, y: number): void {
        if (this.editorService.getState() !== EditorState.SELECTING) {
            return;
        }

        this.editorService.setMouseCoords(x, y);

        const lastMouseCoords = this.editorService.getLastMouseCoords();
        if (!lastMouseCoords) {
            return;
        }

        const [lastX, lastY] = lastMouseCoords;

        const dimensions = this.createRectangleFromPoints(x, y, lastX, lastY);
        setSelectionDimensions(dimensions);

        renderCurrentCanvasFrame();
    }

    handleTranslateMove(x: number, y: number): void {
        const lastMouseCoords = this.editorService.getLastMouseCoords();
        if (!lastMouseCoords) {
            return;
        }

        const [ lastX, lastY ] = lastMouseCoords;
        const dx = x - lastX;
        const dy = y - lastY;

        const [ offsetX, offsetY ] = this.editorService.getFloatingFrameOffset();
        this.editorService.setFloatingFrameOffset(offsetX + dx, offsetY + dy);

        const userSelection = this.editorService.getUserSelection();
        if (userSelection) {
            const { x: a, y: b, width, height} = userSelection;
            this.editorService.setUserSelection(a + dx, b + dy, width, height);
            setSelectionDimensions({x: a + dx, y: b + dy, width, height});
        }

        this.editorService.setLastMouseCoords(x, y);
        renderCurrentCanvasFrame();
    }

    handleStrokeEnd(): void {
        if (this.editorService.getState() !== EditorState.DRAWING) {
            return;
        }

        if (!this.editorService.setState(EditorState.IDLE)) {
            return;
        }

        this.editorService.resetMouseCoords();

        const currentStroke = this.editorService.getCurrentStroke();
        if (currentStroke) {
            this.editorService.getUserEditHistoryController().addCommand(currentStroke);
            this.editorService.setCurrentStroke(undefined);

            const strokeEndCommand: StrokeEndMessage = {
                type: CommandType.STROKE_END,
            }

            this.sendJSONMessage(strokeEndCommand);
        }
    }

    handleSelectEnd(x: number, y: number): void {
        if (this.editorService.getState() !== EditorState.SELECTING) {
            return;
        }


        this.editorService.setMouseCoords(x, y);
        const lastMouseCoords = this.editorService.getLastMouseCoords();

        if (lastMouseCoords) {
            const [ lastX, lastY ] = lastMouseCoords;

            if (x === lastX && y === lastY) {
                return this.transitionToIdle();
            }

            if (!this.editorService.setState(EditorState.SELECTION_IDLE)) {
                return;
            }

        const dimensions = this.createRectangleFromPoints(x, y, lastX, lastY);

            const {panX, panY} = this.editorService.getCanvasViewTransformations();
            this.editorService.setUserSelection(
                dimensions.x - panX,
                dimensions.y - panY,
                dimensions.width,
                dimensions.height
            );
        }

        this.editorService.resetMouseCoords();
        renderCurrentCanvasFrame();
    }

    transitionToIdle(): void {
        if (!this.editorService.setState(EditorState.IDLE)) {
            return;
        }

        this.editorService.resetUserSelection();
        this.editorService.resetMouseCoords();
        this.editorService.setFloatingFrameOffset(0, 0);
        setSelectionDimensions({ x: 0, y: 0, width: 0, height: 0 });
    }

    handleTranslateEnd(): void {
        this.editorService.setState(EditorState.TRANSFORMING);
        this.editorService.resetMouseCoords();
        renderCurrentCanvasFrame();
    }

    handlePanEnd(): void {
        if (this.editorService.getState() !== EditorState.PANNING) {
            return;
        }

        this.editorService.setState(this.editorService.editorStatePop());

        this.editorService.resetMouseCoords();
        renderCurrentCanvasFrame();
    }

    undo(): void {
        if (this.editorService.getState() !== EditorState.IDLE) {
            return;
        }

        const frameId = this.editorService.getUserEditHistoryController().undo();

        if (frameId == null) {
            return;
        }

        this.sendJSONMessage({ type: CommandType.UNDO });

        if (frameId !== this.editorService.getCurrentFrameId()) {
            this.editorService.setCurrentFrameId(frameId);
            const currentFrame = this.editorService.getAnimation().getFrameById(this.editorService.getCurrentFrameId());

            if (currentFrame) {
                this.editorUIState?.setCurrentFrameIndex(currentFrame.getIndex());
            }
        }

        renderCurrentCanvasFrame();
    }

    redo(): void {
        if (this.editorService.getState() !== EditorState.IDLE) {
            return;
        }

        const frameId = this.editorService.getUserEditHistoryController().redo();
        if (frameId == null) {
            return;
        }

        this.sendJSONMessage({ type: CommandType.REDO });

        if (frameId !== this.editorService.getCurrentFrameId()) {
            this.editorService.setCurrentFrameId(frameId);
            const currentFrame = this.editorService.getAnimation().getFrameById(this.editorService.getCurrentFrameId());

            if (currentFrame) {
                this.editorUIState?.setCurrentFrameIndex(currentFrame.getIndex());
            }
        }

        renderCurrentCanvasFrame();
    }

    startPlayback(): void {
        if (!this.editorService.setState(EditorState.PLAYING)) {
            return;
        }

        const currentFrame = this.editorService.getAnimation().getFrameById(this.editorService.getCurrentFrameId());

        if (!this.editorService.getAnimation().getLoop() && currentFrame && currentFrame.getIndex() === this.editorService.getAnimation().getFrameCount() - 1) {
            const firstFrame = this.editorService.getAnimation().getFirstFrame();
            this.editorService.setCurrentFrameId(firstFrame.getId());
            this.editorUIState?.setCurrentFrameIndex(firstFrame.getIndex());
            renderCurrentCanvasFrame();
        }

        const playbackState = this.editorService.getPlaybackState();
        playbackState.timeCounter = 0;
        playbackState.requestId = requestAnimationFrame(updateCanvas);

        playbackState.benchmarkFrameCount = 0;
        playbackState.benchmarkTotalTime = 0;

        this.editorUIState?.setPlaying(true);
    }

    stopPlayback(): void {
        if (!this.editorService.setState(EditorState.IDLE)) {
            return;
        }

        const playbackState = this.editorService.getPlaybackState();
        if (playbackState.requestId) {
            cancelAnimationFrame(playbackState.requestId);
        }

        playbackState.requestId = undefined;
        playbackState.lastFrameTimestamp = undefined;

        console.log({
            frameCount: playbackState.benchmarkFrameCount,
            totalTime: playbackState.benchmarkTotalTime,
            fps: playbackState.benchmarkFrameCount / (playbackState.benchmarkTotalTime / 1000),
        });

        this.editorUIState?.setPlaying(false);

        const currentFrame = this.editorService.getAnimation().getFrameById(this.editorService.getCurrentFrameId());

        if (currentFrame) {
            const [colourA, colourB, colourC] = currentFrame.getColourPalette();
            this.editorUIState?.setColourA(colourToHex(colourA));
            this.editorUIState?.setColourB(colourToHex(colourB));
            this.editorUIState?.setColourC(colourToHex(colourC));
            this.editorUIState?.setCurrentColour(this.editorService.getCurrentColourPalette()[this.editorService.getCurrentStrokeColour() - 1]);
            this.editorUIState?.setCurrentPaperColour(colourToHex(currentFrame.getPaperColour()));
        }

        renderCurrentCanvasFrame();

    }

    togglePlayPause(): void {
        if ([EditorState.IDLE, EditorState.SELECTION_IDLE].includes(this.editorService.getState())) {
            this.startPlayback();
        } else if (this.editorService.getState() === EditorState.PLAYING) {
            this.stopPlayback();
        }
    }

    sendJSONMessage(message: Message): void {
        const websocket = this.editorService.getWebSocket();

        if (!websocket || websocket?.readyState !== WebSocket.OPEN) {
            return;
        }

        websocket.send(JSON.stringify({...message, userId: this.editorService.getUserId()}));
    }

    pasteToFloatingLayer(): void {
        if (this.editorService.getState() === EditorState.TRANSFORMING) {
            this.deselect();
        }

        const floatingFrame = this.editorService.getFloatingFrame();

        const clipboardData = this.editorService.getUserClipboard().getClipboardData();

        if (!clipboardData) {
            return;
        }

        const { data, layerIndex, dimensions } = clipboardData;
        const { x, y, width, height } = dimensions;

        this.editorService.setState(EditorState.TRANSFORMING);
        this.editorService.setUserSelection(x, y, width, height);
        setSelectionDimensions(dimensions);

        if (data && layerIndex != null) {
            const minX = Math.max(0, x);
            const maxX = Math.min(Math.floor(CANVAS_WIDTH / HORIZONTAL_SCALE), x + width);
            const minY = Math.max(0, y);
            const maxY = Math.min(Math.floor(CANVAS_HEIGHT / VERTICAL_SCALE), y + height);

            for (let i = minX; i < maxX; i++) {
                for (let j = minY; j < maxY; j++) {
                    copyPixelFromSource(i, j, data, layerIndex, floatingFrame, this.editorService.getCurrentLayerIndex(), true);
                }
            }

            renderCurrentCanvasFrame();
        }
    }

    pasteFromFloatingLayerToCurrentLayer(): void {
        const floatingFrame = this.editorService.getFloatingFrame();

        const userSelection = this.editorService.getUserSelection();
        if (!userSelection) {
            return;
        }

        const floatingFrameOffset = this.editorService.getFloatingFrameOffset();

        if (!floatingFrameOffset) {
            return;
        }

        const { panX, panY } = this.editorService.getCanvasViewTransformations();

        const [ offsetX, offsetY ] = floatingFrameOffset;
        const { x, y, width, height } = userSelection;

        const pannedFloatingFrameOffset: Point = [offsetX - panX, offsetY - panY];
        const pannedUserSelection = { x: x - panX, y: y - panY, width, height };

        const layerIndex = this.editorService.getCurrentLayerIndex();
        const currentFrame = this.editorService.getAnimation().getFrameById(this.editorService.getCurrentFrameId());
        const floatingFrameData = floatingFrame.getData();

        if (!currentFrame) {
            return;
        }

        const pasteCommand = new PasteCommand(
            JSON.parse(JSON.stringify(userSelection)),
            JSON.parse(JSON.stringify(floatingFrameOffset)),
            // pannedUserSelection,
            // pannedFloatingFrameOffset,
            floatingFrameData,
            layerIndex,
            currentFrame,
            layerIndex,
        );

        pasteCommand.execute();
        this.editorService.getUserEditHistoryController().addCommand(pasteCommand);
        this.sendJSONMessage(pasteCommand.serialize());

        for (let i = 0; i < floatingFrame.getData().length; i++) {
            floatingFrameData[i] = ColourIndex.CLEAR;
        }
    }

    selectAll(): void {
        if (!this.editorService.setState(EditorState.SELECTION_IDLE)) {
            return;
        }

        this.editorService.setUserSelection(0, 0, CANVAS_WIDTH,  CANVAS_HEIGHT);
        setSelectionDimensions({ x: 0, y: 0, width: CANVAS_WIDTH, height: CANVAS_HEIGHT });
        renderCurrentCanvasFrame();
    }

    deselect(): void {
        if (this.editorService.getState() === EditorState.TRANSFORMING) {
            this.pasteFromFloatingLayerToCurrentLayer();
        }

        this.transitionToIdle();

        renderCurrentCanvasFrame();
    }

    deleteInsideSelection(): void {
        if (this.editorService.getState() !== EditorState.SELECTION_IDLE) {
            return;
        }

        const userSelection = this.editorService.getUserSelection();

        if (!userSelection) {
            return;
        }

        const currentFrame = this.editorService.getAnimation().getFrameById(this.editorService.getCurrentFrameId());
        if (!currentFrame) {
            return;
        }

        const currentLayerIndex = this.editorService.getCurrentLayerIndex();

        const deleteSelectionCommand = new DeleteSelectionCommand(
            userSelection,
            currentFrame,
            currentLayerIndex,
        );

        deleteSelectionCommand.execute();
        this.editorService.getUserEditHistoryController().addCommand(deleteSelectionCommand);
        this.sendJSONMessage(deleteSelectionCommand.serialize());

        renderCurrentCanvasFrame();
    }
    
    copySelection(): void {
        if (this.editorService.getState() !== EditorState.SELECTION_IDLE) {
            return;
        }

        const frame = this.editorService.getAnimation().getFrameById(this.editorService.getCurrentFrameId());
        const userSelection = this.editorService.getUserSelection();

        if (frame && userSelection) {
            const clipboard = this.editorService.getUserClipboard();
            clipboard.copy(frame, this.editorService.getCurrentLayerIndex(), userSelection);
            const message = clipboard.createCopyMessage();

            if (message) {
                this.sendJSONMessage(message);
            }
        }
    }

    cutSelection(): void {
        this.copySelection();
        this.deleteInsideSelection();
    }

    setCurrentLayerIndex(layerIndex: number): void {
        const prohibitedStates = [
            EditorState.DRAWING,
            EditorState.TRANSFORMING,
            EditorState.TRANSLATING,
        ];

        if (prohibitedStates.includes(this.editorService.getState())) {
            return;
        }

        this.editorService.setCurrentLayerIndex(layerIndex);
        this.editorUIState?.setCurrentDrawLayer(layerIndex);
    }

    toggleLayerVisibility(layerIndex: number): void {
        let [backgroundVisible, middlegroundVisible, foregroundVisible] = this.editorService.getLayerVisibility();

        if (layerIndex === 0) {
            backgroundVisible = !backgroundVisible;
        } else if (layerIndex === 1) {
            middlegroundVisible = !middlegroundVisible;
        } else if (layerIndex === 2) {
            foregroundVisible = !foregroundVisible;
        }

        this.editorService.setLayerVisibility(backgroundVisible, middlegroundVisible, foregroundVisible);
        this.editorUIState?.setBackgroundVisibility(backgroundVisible);
        this.editorUIState?.setMiddlegroundVisibility(middlegroundVisible);
        this.editorUIState?.setForegroundVisibility(foregroundVisible);

        renderCurrentCanvasFrame();
    }

    setFps(fps: number, sendMessage = true): void {
        this.editorService.getAnimation().setFramesPerSecond(fps);
        this.editorUIState?.setFps(fps);

        const message: SetFPSMessage = {
            type: CommandType.SET_FPS,
            fps,
        }

        if (sendMessage) {
            this.sendJSONMessage(message);
        }
    }

    increaseFps(): void {
        if (this.editorService.getAnimation().getFramesPerSecond() < MAX_FPS) {
            const newFps = this.editorService.getAnimation().getFramesPerSecond() + 1;
            this.setFps(newFps);
        }
    }

    decreaseFps(): void {
        if (this.editorService.getAnimation().getFramesPerSecond() > MIN_FPS) {
            const newFps = this.editorService.getAnimation().getFramesPerSecond() - 1;
            this.setFps(newFps);
        }
    }

    insertFrame(before = true): void {
        const currentPaperColour = this.editorService.getAnimation().getFrameById(this.editorService.getCurrentFrameId())?.getPaperColour();
        const result = before 
            ? createFrameInsertMessage(this.editorService.getAnimation(), this.editorService.getCurrentFrameId(), this.editorService.getCurrentColourPalette(), currentPaperColour)
            : createFrameInsertMessage(this.editorService.getAnimation(), this.editorService.getAnimation().getNextFrame(this.editorService.getCurrentFrameId())?.getId(), this.editorService.getCurrentColourPalette(), currentPaperColour);

        if (result) {
            const [frame, frameInsertMessage] = result;

            this.editorService.setCurrentFrameId(frame.getId());
            this.editorUIState?.setCurrentFrameIndex(frame.getIndex());
            this.editorUIState?.setFrameCount(this.editorService.getAnimation().getFrameCount());

            this.sendJSONMessage(frameInsertMessage);
            renderCurrentCanvasFrame();
        }
    }

    deleteCurrentFrame(): void {
        if (this.editorService.getState() !== EditorState.IDLE) {
            return;
        }

        const result = createFrameDeleteMessage(this.editorService.getAnimation(), this.editorService.getCurrentFrameId());
        if (result) {
            const [fallbackFrame, frameDeleteMessage] = result;

            if (!fallbackFrame) {
                return;
            }

            this.editorUIState?.setFrameCount(this.editorService.getAnimation().getFrameCount());
            this.sendJSONMessage(frameDeleteMessage);
            this.showFrameById(fallbackFrame.getId());
        }
    }

    toggleBackwardOnionSkin(): void {
        const [ backward, forward ] = this.editorService.getOnionSkinSettings();
        this.editorService.setOnionSkinSettings(!backward, forward);
        this.editorUIState?.setBackwardOnionSkin(!backward);
        renderCurrentCanvasFrame();
    }

    toggleForwardOnionSkin(): void {
        const [ backward, forward ] = this.editorService.getOnionSkinSettings();
        this.editorService.setOnionSkinSettings(backward, !forward);
        this.editorUIState?.setForwardOnionSkin(!forward);
        renderCurrentCanvasFrame();
    }

    toggleOnionSkinNaturalColours(): void {
        const naturalColours = this.editorService.getOnionSkinColourSetting();
        this.editorService.setOnionSkinColourSetting(!naturalColours);
        this.editorUIState?.setOnionSkinNaturalColours(!naturalColours);
        renderCurrentCanvasFrame();
    }

    setBackwardOnionSkinColour(colour: WebGL2Colour): void {
        this.editorService.setOnionSkinBackwardColour(colour);
        renderCurrentCanvasFrame();
    }

    setForwardOnionSkinColour(colour: WebGL2Colour): void {
        this.editorService.setOnionSkinForwardColour(colour);
        renderCurrentCanvasFrame();
    }

    setFramePaperColour(colour: WebGL2Colour, frameId = this.editorService.getCurrentFrameId(), sendMessage = true): void {
        const frame = this.editorService.getAnimation().getFrameById(frameId);
        if (!frame) {
            return;
        }

        frame.setPaperColour(colour);

        const message: SetPaperColourMessage = {
            type: CommandType.SET_PAPER_COLOUR,
            colour,
            frameId: frame.getId(),
        }

        if (sendMessage) {
            this.sendJSONMessage(message);
        }

        renderCurrentCanvasFrame();
    }

    displayNextFrame(): void {
        if ([EditorState.SELECTION_IDLE, EditorState.TRANSFORMING].includes(this.editorService.getState())) {
            this.deselect();
        }

        if (this.editorService.getState() !== EditorState.IDLE) {
            return;
        }

        const currentFrameId = this.editorService.getCurrentFrameId();
        let nextFrame = this.editorService.getAnimation().getNextFrame(currentFrameId);

        if (!nextFrame) {
            const currentPaperColour = this.editorService.getAnimation().getFrameById(this.editorService.getCurrentFrameId())?.getPaperColour();
            const result = createFrameInsertMessage(this.editorService.getAnimation(), undefined, this.editorService.getCurrentColourPalette(), currentPaperColour);

            if (result) {
                const [frame, frameInsertCommand] = result;
                this.sendJSONMessage(frameInsertCommand);
                this.editorUIState?.setFrameCount(this.editorService.getAnimation().getFrameCount());

                nextFrame = frame;
            } else {
                return;
            }
        }

        this.showFrameById(nextFrame.getId());
    }

    displayPreviousFrame(): void {
        if ([EditorState.SELECTION_IDLE, EditorState.TRANSFORMING].includes(this.editorService.getState())) {
            this.deselect();
        }

        if (this.editorService.getState() !== EditorState.IDLE) {
            return;
        }

        const currentFrameId = this.editorService.getCurrentFrameId();
        let previousFrame = this.editorService.getAnimation().getPreviousFrame(currentFrameId);

        if (!previousFrame) {
            return;
        }

        this.showFrameById(previousFrame.getId());
    }

    showFrameById(frameId: number): void {
        const frame = this.editorService.getAnimation().getFrameById(frameId);

        if (!frame) {
            return;
        }

        this.editorService.setCurrentFrameId(frame.getId());
        this.editorUIState?.setCurrentFrameIndex(frame.getIndex());

        const [colourA, colourB, colourC] = frame.getColourPalette();
        this.editorUIState?.setColourA(colourToHex(colourA));
        this.editorUIState?.setColourB(colourToHex(colourB));
        this.editorUIState?.setColourC(colourToHex(colourC));

        this.editorUIState?.setCurrentColour(this.editorService.getCurrentColourPalette()[this.editorService.getCurrentStrokeColour() - 1]);
        this.editorUIState?.setCurrentPaperColour(colourToHex(frame.getPaperColour()));

        renderCurrentCanvasFrame();
    }

    refreshEditorUI(): void {
        const animation = this.editorService.getAnimation();

        this.editorUIState?.setFrameCount(animation.getFrameCount());
        this.editorUIState?.setFps(animation.getFramesPerSecond());
        this.editorUIState?.setLoop(animation.getLoop());

        this.showFrameById(animation.getFirstFrame().getId());
    }

    panViewLeft(): void {
        const { panX } = this.editorService.getCanvasViewTransformations();
        this.setPan("x", panX + DEFAULT_PAN_RATE);
        renderCurrentCanvasFrame();
    }

    panViewRight(): void {
        const { panX } = this.editorService.getCanvasViewTransformations();
        this.setPan("x", panX - DEFAULT_PAN_RATE);
        renderCurrentCanvasFrame();
    }

    panViewUp(): void {
        const { panY } = this.editorService.getCanvasViewTransformations();
        this.setPan("y", panY + DEFAULT_PAN_RATE);
        renderCurrentCanvasFrame();
    }

    panViewDown(): void {
        const { panY } = this.editorService.getCanvasViewTransformations();
        this.setPan("y", panY - DEFAULT_PAN_RATE);
        renderCurrentCanvasFrame();
    }

    zoomIn(): void {
        const transformations = this.editorService.getCanvasViewTransformations();
        if (transformations.zoom < MAX_ZOOM) {
            transformations.zoom *= 2;
        }

        renderCurrentCanvasFrame();
    }

    zoomOut(): void {
        const transformations = this.editorService.getCanvasViewTransformations();
        if (transformations.zoom > MIN_ZOOM) {
            transformations.zoom /= 2;
        }

        const { panX, panY } = transformations;
        this.setPan("x", panX);
        this.setPan("y", panY);

        renderCurrentCanvasFrame();
    }

    zoomInAtPoint(x: number, y: number): void {
        const transformations = this.editorService.getCanvasViewTransformations();

        if (transformations.zoom < MAX_ZOOM) {
            transformations.zoom *= 2;
        }

        const viewportWidth = Math.floor(CANVAS_WIDTH / transformations.zoom);
        const viewportHeight = Math.floor(CANVAS_HEIGHT / transformations.zoom);

        const targetPanX = -Math.floor(x - viewportWidth / 2);
        const targetPanY = -Math.floor(y - viewportHeight / 2);

        this.setPan("x", targetPanX);
        this.setPan("y", targetPanY);

        renderCurrentCanvasFrame();
    }

    resetCanvasViewTransformations(): void {
        const transformations = this.editorService.getCanvasViewTransformations();
        transformations.zoom = DEFAULT_ZOOM;
        transformations.panX = DEFAULT_PAN;
        transformations.panY = DEFAULT_PAN;
    }

    private setPan(type: "x" | "y", value: number): void {
        const transformations = this.editorService.getCanvasViewTransformations();
        let targetPan = value;

        if (targetPan >= 0) {
            targetPan = 0;
        }

        const length = type === "x" ? CANVAS_WIDTH : CANVAS_HEIGHT;
        const maxPan = length - length / transformations.zoom;

        if (targetPan <= -maxPan) {
            targetPan = -maxPan;
        }

        let oldPan = targetPan;
        if (type === "x") {
            oldPan = transformations.panX;
            transformations.panX = targetPan;
        } else if (type === "y") {
            oldPan = transformations.panY;
            transformations.panY = targetPan;
        }

        if (
            this.editorService.getState() === EditorState.SELECTION_IDLE
            || this.editorService.editorStatePeek() === EditorState.SELECTION_IDLE
        ) {
            const userSelection = this.editorService.getUserSelection();
            if (userSelection) {
                this.editorService.setUserSelection(
                    type === "x" ? userSelection.x - (transformations.panX - oldPan) : userSelection.x,
                    type === "y" ? userSelection.y - (transformations.panY - oldPan) : userSelection.y,
                    userSelection.width,
                    userSelection.height
                );
            }
        }
    }

    private createRectangleFromPoints(x1: number, y1: number, x2: number, y2: number): Rectangle {
        const minX = Math.min(x1, x2);
        const minY = Math.min(y1, y2);
        const width = Math.abs(x1 - x2);
        const height = Math.abs(y1 - y2);

        return { x: minX, y: minY, width, height};
    }
}
