export const animationVertexShaderSource = `#version 300 es
    precision mediump float;

    in vec2 a_position;
    in vec2 a_texCoord;

    uniform vec2 u_resolution;
    uniform float u_horizontalScale;
    uniform float u_verticalScale;

    uniform float u_zoom;
    uniform vec2 u_offset;

    out vec2 v_texCoord;

    void main() {
        vec2 scaledPosition = (a_position + u_offset) * vec2(u_zoom, u_zoom) * vec2(u_horizontalScale, u_verticalScale);
        vec2 normalized_position = scaledPosition / u_resolution;
        vec2 clip_space_position = (normalized_position * 2.0) - 1.0;

        v_texCoord = a_texCoord;

        gl_Position = vec4(clip_space_position * vec2(1, -1), 0, 1);
    }
    `;

export const animationFragmentShaderSource = `#version 300 es
    precision mediump float;
    precision mediump int;
    precision mediump usampler2D;

    uniform usampler2D u_image;
    uniform float u_horizontalScale;
    uniform float u_verticalScale;
    uniform float u_zoom;
    uniform vec2 u_offset;
    uniform float u_opacity;
    uniform vec4[3] u_colours;

    in vec2 v_texCoord;

    out vec4 fragColour;
    
    void main() {
        vec4 colours[4];
        colours[0] = vec4(0.0, 0.0, 0.0, 0.0);
        colours[1] = u_colours[0] / 255.0;
        colours[2] = u_colours[1] / 255.0;
        colours[3] = u_colours[2] / 255.0;

        uint horizontalScale = uint(u_horizontalScale);
        uint verticalScale = uint(u_verticalScale);
        uvec2 position = uvec2(gl_FragCoord.xy - u_offset * u_zoom);

        uvec4 data = uvec4(texture(u_image, vec2(v_texCoord.x, v_texCoord.y)));

        uint pixelsPerByte = 4u;
        uint bitsPerPixel = 2u;

        uint shiftAmount = (position.x / horizontalScale / uint(u_zoom)) % pixelsPerByte;
        uint offset = (pixelsPerByte - shiftAmount) * bitsPerPixel - bitsPerPixel;
        uint colourIndex = (data.r & (3u << offset)) >> offset;

        fragColour = colours[colourIndex] * vec4(1.0, 1.0, 1.0, u_opacity);
    }
    `;

export const selectToolVertexShaderSource = `#version 300 es
    precision mediump float;
    precision mediump int;

    in vec2 a_position;

    uniform vec2 u_resolution;
    uniform float u_horizontalScale;
    uniform float u_verticalScale;
    uniform float u_zoom;
    uniform vec2 u_offset;

    out vec2 v_canvasPosition;

    void main() {
        vec2 scaledPosition = (a_position + u_offset) * vec2(u_zoom, u_zoom) * vec2(u_horizontalScale, u_verticalScale);

        v_canvasPosition = scaledPosition;

        vec2 normalized_position = scaledPosition / u_resolution;
        vec2 clip_space_position = (normalized_position * 2.0) - 1.0;

        gl_Position = vec4(clip_space_position * vec2(1, -1), 0, 1);
    }
`;

export const selectToolFragmentShaderSource = `#version 300 es
    precision mediump float;

    in vec2 v_canvasPosition;

    out vec4 fragColour;
    
    void main() {
        vec4 colours[2];
        colours[0] = vec4(1.0, 1.0, 1.0, 1.0);
        colours[1] = vec4(0.0, 0.0, 0.0, 1.0);

        uvec2 position = uvec2(v_canvasPosition);
        uint colourIndex = ((position.x + position.y) % 10u) / 5u;

        fragColour = colours[colourIndex];
    }
`;
