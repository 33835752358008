import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditorMenu } from "..";
import { getEditorService } from "../EditorService";

interface LayerToolbarButtonProps {
    selected?: boolean,
    menuOpen?: boolean,
    menuVisibility: boolean,
    currentDrawLayer: number,
    currentMenu: EditorMenu,
    setCurrentMenu: (a: EditorMenu) => void,
    setMenuVisibility: (a: boolean) => void,
}

export default function LayerToolbarButton({
    currentDrawLayer,
    selected,
    menuOpen,
    setCurrentMenu,
    setMenuVisibility,
    menuVisibility,
    currentMenu,
}: LayerToolbarButtonProps) {
    const currentLayerColour = "text-white";
    const otherLayerColour = "text-slate-500";

    return (
        <button
            className={`active:text-primary p-1 mt-1 landscape:mt-0 landscape:ml-1 landscape:p-2 rounded-t-xl landscape:rounded-r-none landscape:rounded-l-xl ${menuOpen ? "bg-slate-700" : ""}`}
            onClick={(e) => {
                setCurrentMenu(EditorMenu.LAYER);
                setMenuVisibility(true);
            }}
            onContextMenu={(e) => {
                e.preventDefault();
                setCurrentMenu(EditorMenu.LAYER);
                setMenuVisibility(!(menuVisibility && currentMenu === EditorMenu.LAYER));
            }}
        >
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faSquare} transform="shrink-1 left-3 up-3" className={currentDrawLayer === 0 ? currentLayerColour : otherLayerColour } />
                <FontAwesomeIcon icon={faSquare} transform="shrink-1" className={currentDrawLayer === 1 ? currentLayerColour : otherLayerColour } />
                <FontAwesomeIcon icon={faSquare} transform="shrink-1 right-3 down-3" className={currentDrawLayer === 2 ? currentLayerColour : otherLayerColour } />
            </span>
        </button>
    );
}
