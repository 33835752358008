import Animation from "../Animation";
import { copyPixelFromSource, drawPoint } from "../DrawUtils";
import { ColourIndex } from "../EditorService";
import Frame from "../Frame";
import { Rectangle } from "../GeneralTypes";
import { BrushPattern } from "../enums/BrushPattern";
import { DeleteSelectionMessage } from "../messages/Messages";
import { CommandType } from "./Commands";
import ReversibleCommand from "./ReversibleCommand"

export default class DeleteSelectionCommand extends ReversibleCommand {
    private dimensions: Rectangle;
    private destinationFrame: Frame;
    private originalDestinationFrameData: Uint8Array;
    private destinationLayerIndex: number;

    constructor(
        dimensions: Rectangle,
        destinationFrame: Frame,
        destinationLayerIndex: number,
    ) {
        super(CommandType.DELETE_SELECTION);
        this.dimensions = dimensions;
        this.destinationFrame = destinationFrame;
        this.originalDestinationFrameData = new Uint8Array(destinationFrame.getData());
        this.destinationLayerIndex = destinationLayerIndex;
    }

    execute(): number {
        const { x, y, width, height } = this.dimensions;

        for (let i = x; i < x + width; i++) {
            for (let j = y; j < y + height; j++) {
                drawPoint(i, j, 1, ColourIndex.CLEAR, this.destinationFrame, this.destinationLayerIndex, BrushPattern.SOLID);
            }
        }

        return this.destinationFrame.getId();
    }

    undo(): number {
        const { x, y, width, height } = this.dimensions;

        for (let i = x; i < x + width; i++) {
            for (let j = y; j < y + height; j++) {
                copyPixelFromSource(i, j, this.originalDestinationFrameData, this.destinationLayerIndex, this.destinationFrame, this.destinationLayerIndex, true);
            }
        }

        return this.destinationFrame.getId();
    }

    serialize(): DeleteSelectionMessage {
        const { dimensions, destinationFrame, destinationLayerIndex } = this;
        return {
            type: CommandType.DELETE_SELECTION,
            dimensions,
            frameId: destinationFrame.getId(),
            layerIndex: destinationLayerIndex,
        }
    }

    static deserialize(message: DeleteSelectionMessage, animation: Animation): DeleteSelectionCommand | undefined {
        const { frameId: destinationFrameId, layerIndex: destinationLayerIndex, dimensions } = message;
        const destinationFrame = animation.getFrameById(destinationFrameId);

        if (destinationFrame) {
            return new DeleteSelectionCommand(
                dimensions,
                destinationFrame,
                destinationLayerIndex,
            );
        }
    }
}
