import { useEffect, useRef } from "react";

interface ColourInput {
    value: string;
    showColourDialog?: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onClick?: () => void;
    setValue: React.Dispatch<React.SetStateAction<string>>;
}

export default function ColourInput({
    value,
    showColourDialog = true,
    onChange,
    onClick,
    setValue,
}: ColourInput) {
    const colourInputRef = useRef<HTMLInputElement>(null);

    function handleEvent(e: MouseEvent | TouchEvent): void {
        if (showColourDialog) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        if (onClick) {
            onClick();
        }
    } 

    useEffect(() => {
        if (colourInputRef.current) {
            colourInputRef.current.addEventListener("click", handleEvent, false);
            colourInputRef.current.addEventListener("touchstart", handleEvent, false);
        } 

        return () => {
            if (colourInputRef.current) {
                colourInputRef.current.removeEventListener("click", handleEvent, false);
                colourInputRef.current.removeEventListener("touchstart", handleEvent, false);
            } 
        }
    });

    return (
        <input 
            type="color"
            className="mx-2 w-12 h-12 cursor-pointer"
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
                onChange(e);
            }}
            ref={colourInputRef}
        ></input>
    );
}
