
interface ToggleInputProps {
    label: string;
    value: boolean;
    uncheckedHelpText?: string;
    checkedHelpText?: string;
    setValue: React.Dispatch<React.SetStateAction<boolean>>;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export default function ToggleInput({
    label,
    value,
    uncheckedHelpText,
    checkedHelpText,
    onChange,
    setValue,
}: ToggleInputProps) {
    return (
        <div className="flex flex-col my-5">
            <label className="flex items-center cursor-pointer mb-2">
                <input type="checkbox" className="sr-only peer" checked={value} onChange={onChange ?? (() => setValue(!value))} />
                <div className="relative w-12 h-7 bg-slate-800 peer-focus:outline-none peer-focus:ring-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-primary"></div>
                <span className="ms-3 text-sm">{label}</span>
            </label>
            { !value && uncheckedHelpText && (<p className="text-sm text-slate-300">{uncheckedHelpText}</p>) }
            { value && checkedHelpText && (<p className="text-sm text-slate-300">{checkedHelpText}</p>) }
        </div>
    );
}
