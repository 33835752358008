import Animation from "../Animation";
import { CANVAS_HEIGHT, CANVAS_WIDTH, HORIZONTAL_SCALE, VERTICAL_SCALE } from "../Constants";
import { copyPixelFromSource, copyPixelFromSourceOffset } from "../DrawUtils";
import EditorClipboard from "../EditorClipboard";
import Frame from "../Frame";
import { Point, Rectangle } from "../GeneralTypes";
import { PasteMessage } from "../messages/Messages";
import { CommandType } from "./Commands";
import ReversibleCommand from "./ReversibleCommand";


export default class PasteCommand extends ReversibleCommand {
    private dimensions: Rectangle;
    private offset: Point;
    private sourceFrameData: Uint8Array;
    private sourceLayerIndex: number;
    private destinationFrame: Frame;
    private originalDestinationFrameData: Uint8Array;
    private destinationLayerIndex: number;

    constructor(
        dimensions: Rectangle,
        offset: Point,
        sourceFrameData: Uint8Array,
        sourceLayerIndex: number,
        destinationFrame: Frame,
        destinationLayerIndex: number,
    ) {
        super(CommandType.PASTE);
        this.dimensions = dimensions;
        this.offset = offset;
        this.sourceFrameData = new Uint8Array(sourceFrameData);
        this.sourceLayerIndex = sourceLayerIndex;
        this.destinationFrame = destinationFrame;
        this.originalDestinationFrameData = new Uint8Array(destinationFrame.getData());
        this.destinationLayerIndex = destinationLayerIndex;
    }

    execute(): number {
        const { x, y, width, height } = this.dimensions;
        const [ offsetX, offsetY ] = this.offset;

        const minX = Math.max(0, x);
        const maxX = Math.min(Math.floor(CANVAS_WIDTH / HORIZONTAL_SCALE), x + width);
        const minY = Math.max(0, y);
        const maxY = Math.min(Math.floor(CANVAS_HEIGHT / VERTICAL_SCALE), y + height);

        for (let i = minX; i < maxX; i++) {
            for (let j = minY; j < maxY; j++) {
                copyPixelFromSourceOffset(i - offsetX, j - offsetY, i, j, this.sourceFrameData, this.sourceLayerIndex, this.destinationFrame, this.destinationLayerIndex, true);
            }
        }

        return this.destinationFrame.getId();
    }

    undo(): number {
        const { x, y, width, height } = this.dimensions;

        for (let i = x; i < x + width; i++) {
            for (let j = y; j < y + height; j++) {
                copyPixelFromSource(i, j, this.originalDestinationFrameData, this.destinationLayerIndex, this.destinationFrame, this.destinationLayerIndex, false);
            }
        }

        return this.destinationFrame.getId();
    }

    serialize(): PasteMessage {
        const { destinationFrame, destinationLayerIndex, offset, dimensions } = this;
        return {
            type: CommandType.PASTE,
            dimensions,
            offset,
            frameId: destinationFrame.getId(),
            layerIndex: destinationLayerIndex,
        };
    }

    static deserialize(message: PasteMessage, animation: Animation, clipboard: EditorClipboard): PasteCommand | undefined {
        const { frameId: destinationFrameId, layerIndex: destinationLayerIndex, offset, dimensions } = message;


        const destinationFrame = animation.getFrameById(destinationFrameId);
        const clipboardData = clipboard.getClipboardData();

        if (!clipboardData) {
            return;
        }

        const { data: sourceFrameData, layerIndex: sourceLayerIndex } = clipboardData;

        if (sourceFrameData && destinationFrame && sourceLayerIndex != null) {
            return new PasteCommand(
                dimensions,
                offset,
                sourceFrameData,
                sourceLayerIndex,
                destinationFrame,
                destinationLayerIndex,
            );
        }
    }
}
