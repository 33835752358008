import { WebGL2Colour } from "./Constants";

export function colourToHex(colour: WebGL2Colour): string {
    if (colour == null) {
        return "#00ff00";
    }

    const hex = "#" + colour.slice(0, 3).map(value => (value.toString(16).padStart(2, "0"))).join("");
    return hex;
}

export function hexToColour(hex: string): WebGL2Colour {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);

    return [r, g, b, 255];
}
