import { useState } from "react";
import { DEFAULT_BRUSH_PATTERN, MAX_STROKE_DIAMETER, MIN_STROKE_DIAMETER, WebGL2Colour } from "../Constants";
import { ColourIndex, getEditorService } from "../EditorService";
import { BrushPattern } from "../enums/BrushPattern";
import { hexToColour } from "../EditorUtils";
import { setColourIndex, setCurrentFrameColourPaletteColour } from "../Canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import ColourInput from "../components/ColourInput";
import ToggleInput from "../components/ToggleInput";
import RangeInput from "../components/RangeInput";

interface BrushMenuProps {
    setCurrentColour: (a: WebGL2Colour) => void,
    colourA: string,
    colourB: string,
    colourC: string,
    brushStrokeDiameter: number,
    brushPattern: BrushPattern,
    setColourA: React.Dispatch<React.SetStateAction<string>>;
    setColourB: React.Dispatch<React.SetStateAction<string>>;
    setColourC: React.Dispatch<React.SetStateAction<string>>;
    setBrushStrokeDiameter: (a: number) => void,
    setMenuVisibility: (a: boolean) => void,
    setBrushPattern: (a: BrushPattern) => void,
}

const editorState = getEditorService();

export default function BrushMenu({
    colourA,
    colourB,
    colourC,
    brushStrokeDiameter,
    brushPattern,
    setBrushPattern,
    setCurrentColour,
    setColourA,
    setColourB,
    setColourC,
    setBrushStrokeDiameter,
    setMenuVisibility
}: BrushMenuProps) {
    const [editColours, setEditColours] = useState(false);

    return (
        <div className="p-2 lg:p-4 w-full">
            <div className="flex flex-row">
                <div className="w-1/2 flex justify-start">
                    <h1 className="text-3xl">Brush</h1>
                </div>
                <div className="w-1/2 flex justify-end">
                    <button onClick={() => setMenuVisibility(false)}>
                        <FontAwesomeIcon className="text-2xl fa-fw" icon={faX} />
                    </button>
                </div>
            </div>

            <h2 className="text-xl mt-5">Colour</h2>
            <p className="text-sm text-slate-300" >Shortcut: c</p>
            <div className="flex flex-row justify-start my-2 -ml-2 landscape:w-full">
                <ColourInput
                    value={colourA}
                    setValue={setColourA}
                    onChange={(e) => {
                        setCurrentFrameColourPaletteColour(hexToColour(e.target.value), 0, setCurrentColour);
                    }}
                    onClick={() => {
                        setColourIndex(ColourIndex.A, setCurrentColour);
                    }}
                    showColourDialog={editColours}
                />

                <ColourInput
                    value={colourB}
                    setValue={setColourB}
                    onChange={(e) => {
                        setCurrentFrameColourPaletteColour(hexToColour(e.target.value), 1, setCurrentColour);
                    }}
                    onClick={() => {
                        setColourIndex(ColourIndex.B, setCurrentColour);
                    }}
                    showColourDialog={editColours}
                />

                <ColourInput
                    value={colourC}
                    setValue={setColourC}
                    onChange={(e) => {
                        setCurrentFrameColourPaletteColour(hexToColour(e.target.value), 2, setCurrentColour);
                    }}
                    onClick={() => {
                        setColourIndex(ColourIndex.C, setCurrentColour);
                    }}
                    showColourDialog={editColours}
                />
            </div>

            <ToggleInput
                value={editColours}
                setValue={setEditColours}
                label="Edit Colours"
                checkedHelpText="Click on a colour to change it in the current frame for everyone."
            />

            <h2 className="text-xl mt-5">Stroke Size</h2>
            <p className="text-sm text-slate-300" >Shortcut: up / down, w / s</p>

            <RangeInput
                minValue={MIN_STROKE_DIAMETER}
                maxValue={MAX_STROKE_DIAMETER}
                step={2}
                value={brushStrokeDiameter}
                onChange={(e) => {
                    // TODO put this as some canvas function to check state.drawing
                    const value = Number(e.target.value);
                    setBrushStrokeDiameter(value);
                    editorState.setBrushStrokeDiameter(value);
                }}
            />

            <p> Size: {brushStrokeDiameter}</p>

            <h2 className="text-xl mt-5">Pattern</h2>
            <select
                name="brushPattern"
                className="text-white bg-slate-600 rounded p-2"
                value={brushPattern}
                onChange={(e) => {
                    const newBrushPattern = e.target.value as BrushPattern;
                    setBrushPattern(newBrushPattern);
                    editorState.setBrushPattern(newBrushPattern);
                    e.target.blur();
                }}
            >
                <option value={BrushPattern.SOLID}>Solid</option>
                <option value={BrushPattern.A}>A</option>
                <option value={BrushPattern.B}>B</option>
                <option value={BrushPattern.C}>C</option>
                <option value={BrushPattern.D}>D</option>
                <option value={BrushPattern.E}>E</option>
            </select>
        </div>
    );
}
