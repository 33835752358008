import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WebGL2Colour } from "../Constants";
import { faBrush, faCircle } from "@fortawesome/free-solid-svg-icons";
import { colourToHex } from "../EditorUtils";
import { EditorTool, getEditorService } from "../EditorService";
import { EditorMenu } from "..";
import { getEditorController } from "../EditorController";

interface BrushToolbarButtonProps {
    selected?: boolean,
    menuOpen?: boolean,
    menuVisibility: boolean,
    currentMenu: EditorMenu,
    colour: WebGL2Colour,
    setCurrentMenu: (a: EditorMenu) => void,
    setMenuVisibility: (a: boolean) => void,
}

const editorController = getEditorController();

export default function BrushToolbarButton({
    selected,
    menuOpen,
    menuVisibility,
    colour,
    currentMenu,
    setCurrentMenu,
    setMenuVisibility
}: BrushToolbarButtonProps) {
    const buttonClassName =
        `active:text-primary p-1 mt-1 landscape:mt-0 landscape:ml-1 landscape:p-2 rounded-t-xl landscape:rounded-r-none landscape:rounded-l-xl  ${selected ? "text-primary" : ""} ${menuOpen ? "bg-slate-700" : ""}`
    return (
        <button
            className={buttonClassName}
            onClick={(e) => {
                editorController.setCurrentTool(EditorTool.BRUSH);
                setCurrentMenu(EditorMenu.BRUSH);
            }}
            onContextMenu={(e) => {
                e.preventDefault();
                setCurrentMenu(EditorMenu.BRUSH);
                setMenuVisibility(!(menuVisibility && currentMenu === EditorMenu.BRUSH));
            }}
        >
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faBrush} fixedWidth />
                <FontAwesomeIcon style={{ color: "white" }} icon={faCircle} transform="shrink-10 right-4 down-6" />
                <FontAwesomeIcon style={{ color: colourToHex(colour) }} icon={faCircle} transform="shrink-11 right-4 down-6" />
            </span>
        </button>
    );
}
