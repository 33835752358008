import { BrushPattern } from "./enums/BrushPattern";

export const ANISHARE = "ANISHARE";
export const BITS_PER_PIXEL = 2;
export const PIXELS_PER_BYTE = 4;
export const PIXEL_MASK = 0b00000011;
export const BYTE_MASK = 0b11111111;

interface AnimationDimensionConstants {
    CANVAS_WIDTH: number,
    CANVAS_HEIGHT: number,
    INTERNAL_WIDTH: number,
    INTERNAL_HEIGHT: number,
    LAYER_WIDTH: number,
    LAYER_HEIGHT: number,
    LAYERS_PER_FRAME: number,
    FRAME_TEXTURE_WIDTH: number,
    FRAME_TEXTURE_HEIGHT: number,
    HORIZONTAL_SCALE: number,
    VERTICAL_SCALE: number,
}

function setAnimationDimensionConstants(canvasWidth: number, canvasHeight: number, internalWidth: number, internalHeight: number, layerCount: number): AnimationDimensionConstants {
    const layerWidth = Math.floor(internalWidth / PIXELS_PER_BYTE);

    return {
        CANVAS_WIDTH: canvasWidth,
        CANVAS_HEIGHT: canvasHeight,
        INTERNAL_WIDTH: internalWidth,
        INTERNAL_HEIGHT: internalHeight,
        LAYERS_PER_FRAME: layerCount,
        LAYER_WIDTH: layerWidth,
        LAYER_HEIGHT: internalHeight,
        FRAME_TEXTURE_WIDTH: layerWidth * layerCount,
        FRAME_TEXTURE_HEIGHT: internalHeight,
        HORIZONTAL_SCALE: Math.floor(canvasWidth / internalWidth),
        VERTICAL_SCALE: Math.floor(canvasHeight / internalHeight),
    }
}

export const {
    CANVAS_WIDTH,
    CANVAS_HEIGHT,
    INTERNAL_WIDTH,
    INTERNAL_HEIGHT,
    LAYERS_PER_FRAME,
    LAYER_WIDTH,
    LAYER_HEIGHT,
    FRAME_TEXTURE_WIDTH,
    FRAME_TEXTURE_HEIGHT,
    HORIZONTAL_SCALE,
    VERTICAL_SCALE,
} = setAnimationDimensionConstants(720, 720, 720, 720, 3);

export type WebGL2Colour = [number, number, number, number];
export type ColourPalette = [WebGL2Colour, WebGL2Colour, WebGL2Colour];

export const COLOUR_CLEAR: WebGL2Colour = [0, 0, 0, 0];
export const COLOUR_WHITE: WebGL2Colour = [255, 255, 255, 255];
export const COLOUR_BLACK: WebGL2Colour = [0, 0, 0, 255];
export const COLOUR_RED: WebGL2Colour = [255, 0, 0, 255];
export const COLOUR_BLUE: WebGL2Colour = [0, 0, 255, 255];

export const DEFAULT_STROKE_DIAMETER = 3;
export const DEFAULT_DRAW_MASK = 1;
export const DEFAULT_DRAW_LAYER = 1;
export const DEFAULT_FRAMES_PER_SECOND = 12;
export const DEFAULT_LOOP_VALUE = true;
export const DEFAULT_ONION_SKIN_FRAME_COUNT = 1;
export const DEFAULT_BRUSH_PATTERN = BrushPattern.SOLID;
export const DEFAULT_COLOUR_PALETTE: ColourPalette = [COLOUR_BLACK, COLOUR_RED, COLOUR_BLUE];
export const DEFAULT_USERS_PER_SESSION = 1;
export const DEFAULT_BACKWARD_ONION_SKIN_COLOUR: WebGL2Colour = [255, 0, 0, 255];
export const DEFAULT_FORWARD_ONION_SKIN_COLOUR: WebGL2Colour = [0, 168, 102, 255];
export const DEFAULT_REMIXABLE_VALUE = false;
export const DEFAULT_ONION_SKIN_NATURAL_COLOURS = false;
export const DEFAULT_ZOOM = 1;
export const DEFAULT_PAN_RATE = 10;
export const DEFAULT_PAN = 0;

export const MAX_ANIMATION_FRAME_COUNT = 240;
export const MIN_ANIMATION_FRAME_COUNT = 1;
export const MIN_STROKE_DIAMETER = 1;
export const MAX_STROKE_DIAMETER = 31;
export const MAX_USERS_PER_SESSION = 4;
export const MIN_FPS = 1;
export const MAX_FPS = 24;
export const MAX_COMMAND_STACK_SIZE = 10;
export const MIN_ZOOM = 1;
export const MAX_ZOOM = 32;

export const PROD_BASE_URL = "demo.anishare.co";
