import Animation from "../Animation";
import { copyLineFromSource, copyPointFromSource, drawLine, drawPoint } from "../DrawUtils";
import Frame from "../Frame";
import { CommandType } from "./Commands";
import { Point, Line } from "../GeneralTypes";
import ReversibleCommand from "./ReversibleCommand";
import { StrokeStartMessage } from "../messages/Messages";
import { ColourIndex } from "../EditorService";
import { BrushPattern } from "../enums/BrushPattern";

export default class StrokeCommand extends ReversibleCommand {
    private subStrokes: (Point | Line)[];
    private diameter: number;
    private colourPaletteIndex: ColourIndex;
    private frame: Frame;
    private frameDataCopy: Uint8Array;
    private layerIndex: number;
    private brushPattern: BrushPattern;

    constructor(
        diameter: number,
        colourPaletteIndex: number,
        frame: Frame,
        layerIndex: number,
        brushPattern: BrushPattern,
    ) {
        super(CommandType.STROKE);

        this.subStrokes = [];
        this.diameter = diameter;
        this.colourPaletteIndex = colourPaletteIndex;
        this.frame = frame;
        this.frameDataCopy = new Uint8Array(frame.getData());
        this.layerIndex = layerIndex;
        this.brushPattern = brushPattern;
    }

    addSubStroke(subStroke: Point | Line): void {
        this.subStrokes.push(subStroke);
    }

    drawSubStroke(subStroke: Point | Line): void {
        if (subStroke.length === 2) {
            const [x, y] = subStroke;
            drawPoint(x, y, this.diameter, this.colourPaletteIndex, this.frame, this.layerIndex, this.brushPattern);
        } else if (subStroke.length === 4) {
            const [x1, y1, x2, y2] = (subStroke as Line);
            drawLine(x1, y1, x2, y2, this.diameter, this.colourPaletteIndex, this.frame, this.layerIndex, this.brushPattern);
        }
    }

    execute(): number {
        for (let i = 0; i < this.subStrokes.length; i++) {
            if (this.subStrokes[i].length === 2) {
                const [x, y] = this.subStrokes[i];
                drawPoint(x, y, this.diameter, this.colourPaletteIndex, this.frame, this.layerIndex, this.brushPattern);
            } else if (this.subStrokes[i].length === 4) {
                const [x1, y1, x2, y2] = (this.subStrokes[i] as Line);
                drawLine(x1, y1, x2, y2, this.diameter, this.colourPaletteIndex, this.frame, this.layerIndex, this.brushPattern);
            }
        }

        return this.frame.getId();
    }

    undo(): number {
        for (let i = 0; i < this.subStrokes.length; i++) {
            if (this.subStrokes[i].length === 2) {
                const [x, y] = this.subStrokes[i];
                copyPointFromSource(x, y, this.diameter, this.frameDataCopy, this.layerIndex, this.frame, this.layerIndex);
            } else if (this.subStrokes[i].length === 4) {
                const [x1, y1, x2, y2] = (this.subStrokes[i] as Line);
                copyLineFromSource(x1, y1, x2, y2, this.diameter, this.frameDataCopy, this.layerIndex, this.frame, this.layerIndex);
            }
        }

        return this.frame.getId();
    }

    serialize(): StrokeStartMessage {
        const { diameter, colourPaletteIndex, frame, layerIndex, brushPattern } = this;
        return {
            type: CommandType.STROKE_START,
            diameter,
            colourPaletteIndex,
            frameId: frame.getId(),
            layerIndex,
            brushPattern,
        };
    }

    getFrameId(): number {
        return this.frame.getId();
    }

    static deserialize(message: StrokeStartMessage, animation: Animation): StrokeCommand | undefined {
        const { diameter, colourPaletteIndex, frameId, layerIndex, brushPattern } = message;
        const frame = animation.getFrameById(frameId);

        if (frame) {
            return new StrokeCommand(diameter, colourPaletteIndex, frame, layerIndex, brushPattern);
        }
    }
}
