import { faBorderAll, faBorderNone, faCopy, faCut, faDeleteLeft, faPaste, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditorButton from "../components/EditorButton";
import { getEditorController } from "../EditorController";

interface SelectMenuProps {
    setMenuVisibility: (a: boolean) => void,
}

const editorController = getEditorController();

export default function SelectMenu({
    setMenuVisibility,
}: SelectMenuProps) {
    return (
        <div className="p-2 lg:p-4 w-full">
            <div className="flex flex-row">
                <div className="w-1/2 flex justify-start">
                    <h1 className="text-3xl">Select</h1>
                </div>
                <div className="w-1/2 flex justify-end">
                    <button onClick={() => setMenuVisibility(false)}>
                        <FontAwesomeIcon className="text-2xl fa-fw" icon={faX} />
                    </button>
                </div>
            </div>

            <h2 className="text-xl mt-5">Selection</h2>

            <EditorButton
                icon={faBorderAll}
                text="Select All"
                shortcutText="ctrl + a"
                onClick={() => {
                    editorController.selectAll();
                }}
            />

            <EditorButton
                icon={faBorderNone}
                text="Deselect"
                shortcutText="escape"
                onClick={() => {
                    editorController.deselect();
                }}
            />

            <EditorButton
                icon={faDeleteLeft}
                text="Delete Selection"
                shortcutText="backspace"
                onClick={() => {
                    editorController.deleteInsideSelection();
                }}
            />

            <h2 className="text-xl mt-5">Clipboard</h2>

            <EditorButton
                icon={faCut}
                text="Cut"
                shortcutText="ctrl + x"
                onClick={() => {
                    editorController.cutSelection();
                }}
            />

            <EditorButton
                icon={faCopy}
                text="Copy"
                shortcutText="ctrl + c"
                onClick={() => {
                    editorController.copySelection();
                }}
            />

            <EditorButton
                icon={faPaste}
                text="Paste"
                shortcutText="ctrl + v"
                onClick={() => {
                    editorController.pasteToFloatingLayer();
                }}
            />
        </div>
    );
}