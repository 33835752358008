import { CommandType } from "./Commands";

export default abstract class ReversibleCommand {
    private type: CommandType;

    constructor(type: CommandType) {
        this.type = type;
    }

    abstract execute(): number; // frameId
    abstract undo(): number; // frameId

    getType(): CommandType {
        return this.type;
    }
}