import { faBorderAll, faBorderNone, faCopy, faCut, faDeleteLeft, faPaste, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditorButton from "../components/EditorButton";
import { getEditorController } from "../EditorController";

interface HandMenu {
    setMenuVisibility: (a: boolean) => void,
}

const editorController = getEditorController();

export default function HandMenu({
    setMenuVisibility,
}: HandMenu) {
    return (
        <div className="p-2 lg:p-4 w-full">
            <div className="flex flex-row">
                <div className="w-1/2 flex justify-start">
                    <h1 className="text-3xl">Hand</h1>
                </div>
                <div className="w-1/2 flex justify-end">
                    <button onClick={() => setMenuVisibility(false)}>
                        <FontAwesomeIcon className="text-2xl fa-fw" icon={faX} />
                    </button>
                </div>
            </div>
            <p>WIP menu for zooming and panning around the canvas view. Will be a proper tool soon, actions may change.</p>

            <h2 className="text-xl mt-5">Zoom</h2>
            <h3 className="text-lg mt-2">Zoom In (at Cursor)</h3>
            <p>Right Click</p>
            <h3 className="text-lg mt-2">Zoom Out</h3>
            <p>Control + Right Click</p>

            <h2 className="text-xl mt-5">Pan</h2>
            <h3 className="text-lg mt-2">Mouse</h3>
            <p>Shift + Drag (using left click)</p>
            <h3 className="text-lg mt-2">Keyboard</h3>
            <p>Shift + WASD / Arrow Keys</p>

            <h2 className="text-xl mt-5">Reset View</h2>
            <p>Shortcut: r</p>
        </div>
    );
}