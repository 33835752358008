import { MAX_COMMAND_STACK_SIZE } from "./Constants";
import ReversibleCommand from "./commands/ReversibleCommand";

export default class EditHistoryController {
    private undoStack: ReversibleCommand[];
    private redoStack: ReversibleCommand[];

    constructor() {
        this.undoStack = [];
        this.redoStack = [];
    }

    undo(): number | undefined {
        const command = this.undoStack.pop();

        if (!command) {
            return;
        }

        this.redoStack.push(command);
        return command.undo();
    }

    redo(): number | undefined {
        const command = this.redoStack.pop();

        if (!command) {
            return;
        }

        this.undoStack.push(command);
        return command.execute();
    }

    addCommand(command: ReversibleCommand): void {
        if (this.undoStack.length >= MAX_COMMAND_STACK_SIZE) {
            this.undoStack.shift();
        }

        this.undoStack.push(command);
        this.redoStack = [];
    }
}