import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditorButton from "../components/EditorButton";
import { getEditorController } from "../EditorController";
import ToggleInput from "../components/ToggleInput";

interface LayerMenuProps {
    currentDrawLayer: number,
    backgroundVisibility: boolean,
    middlegroundVisibility: boolean,
    foregroundVisibility: boolean,
    setMenuVisibility: (a: boolean) => void,
    setBackgroundVisibility: React.Dispatch<React.SetStateAction<boolean>>;
    setMiddlegroundVisibility: React.Dispatch<React.SetStateAction<boolean>>;
    setForegroundVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function LayerMenu({
    backgroundVisibility,
    middlegroundVisibility,
    foregroundVisibility,
    setMenuVisibility,
    setBackgroundVisibility,
    setMiddlegroundVisibility,
    setForegroundVisibility,
    currentDrawLayer,
}: LayerMenuProps) {

    return (
        <div className="p-2 lg:p-4 w-full">
            <div className="flex flex-row">
                <div className="w-1/2 flex justify-start">
                    <h1 className="text-3xl">Layer</h1>
                </div>
                <div className="w-1/2 flex justify-end">
                    <button onClick={() => setMenuVisibility(false)}>
                        <FontAwesomeIcon className="text-2xl fa-fw" icon={faX} />
                    </button>
                </div>
            </div>

            <h2 className="text-xl mt-5">Set Current Layer</h2>
            <p className="text-sm text-slate-300" >Shortcut: <span className="font-mono">l</span></p>
            <p className="text-sm text-slate-300">Sets the current layer you are editing.</p>
            <EditorButton
                text="Go to Background"
                disabled={currentDrawLayer === 0}
                onClick={() => {
                    getEditorController().setCurrentLayerIndex(0);
                }}
            />

            <EditorButton
                text="Go to Middleground"
                disabled={currentDrawLayer === 1}
                onClick={() => {
                    getEditorController().setCurrentLayerIndex(1);
                }}
            />

            <EditorButton
                text="Go to Foreground"
                disabled={currentDrawLayer === 2}
                onClick={() => {
                    getEditorController().setCurrentLayerIndex(2);
                }}
            />


            <h2 className="text-xl mt-5">Show / Hide Layers</h2>
            <p className="text-sm text-slate-300" >Set the visibility of a layer just for you.</p>

            <p className="mt-5">Background</p>
            <ToggleInput
                label={backgroundVisibility ? "Visible" : "Hidden"}
                value={backgroundVisibility}
                setValue={setBackgroundVisibility}
                onChange={() => getEditorController().toggleLayerVisibility(0) }
            />

            <p>Middleground</p>
            <ToggleInput
                label={middlegroundVisibility ? "Visible" : "Hidden"}
                value={middlegroundVisibility}
                setValue={setMiddlegroundVisibility}
                onChange={() => getEditorController().toggleLayerVisibility(1) }
            />

            <p>Foreground</p>
            <ToggleInput
                label={foregroundVisibility ? "Visible" : "Hidden"}
                value={foregroundVisibility}
                setValue={setForegroundVisibility}
                onChange={() => getEditorController().toggleLayerVisibility(2) }
            />
        </div>
    );

}